import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import Header from "./header/Header";
import LeftSideBar from "./header/LeftSideBar";

import { logout } from "../actions/auth";

class SuperAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedin: false,
      aside_left: true,
      aside_right: false
    };
  }

  componentDidMount() {
    // this.props.checkUserToken(localStorage.getItem("token"), 1);
  }
  componentDidUpdate() {
    if (this.props.auth.logOut === true) {
      this.props.logout();
    }
  }

  handleLeftHemburger = () => {
    this.setState({ aside_left: !this.state.aside_left });
  };

  render() {
    if (this.props.auth.logOut === true) {
      return <Redirect to="/" />;
    }

    return (
      
       
        <LeftSideBar />
      
    );
  }
}
function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { logout })(SuperAdmin);
