import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./components/Login/login";
import SuperAdmin from "./components/index";
import AgentEnquiry from "./components/agent/enquiry";
import { checkUserToken } from "./actions/auth";
import { calculateAgGrid } from "./actions";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
//import logo from "./logo.svg";
//import "./resources/css/application.css";
//import "./resources/css/sb-admin-2.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//import "./resources/css/ag-theme-clean.css";
import "./App.css";

class App extends Component {
  componentWillMount = async () => {
    await this.props.calculateAgGrid();
    window.addEventListener("keydown", this.handleKeyDown);
  };

  handleKeyDown(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.altKey && charCode === "p") {
    } else if (event.ctrlKey && charCode === "v") {
    } else if (event.ctrlKey && charCode === "s") {
      event.preventDefault();
    }

    // For MAC we can use metaKey to detect cmd key

    if (event.metaKey && charCode === "c") {
    } else if (event.metaKey && charCode === "v") {
    } else if (event.metaKey && charCode === "s") {
      event.preventDefault();
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <div>
            <Route exact path="/" component={Login} />
            <Route path="/dashboard" component={SuperAdmin} />
            <Route path="/user" component={SuperAdmin} />
            <Route path="/agent" component={SuperAdmin} />
            <Route path="/keygenerator" component={SuperAdmin} />
            <Route path="/purchasekey" component={SuperAdmin} />
            <Route path="/questionbank" component={SuperAdmin} />
            <Route path="/products" component={SuperAdmin} />
            <Route path="/enquiry" component={SuperAdmin} />
            <Route path="/mykeys" component={SuperAdmin} />
            <Route path="/paymentreceipt" component={SuperAdmin} />
            <Route path="/receipt" component={SuperAdmin} />
            <Route path="/userprofile" component={SuperAdmin} />
            <Route path="/settings" component={SuperAdmin} />
            <Route path="/usedkeys" component={SuperAdmin} />
            <Route path="/contactus" exact component={AgentEnquiry} />
          </div>
          <NotificationContainer />
        </div>
      </BrowserRouter>
    );
  }
}
function mapStateToProps({ auth, ui }) {
  return { auth, ui };
}

export default connect(
  mapStateToProps,
  { checkUserToken, calculateAgGrid }
)(App);
