import React, { Component } from "react";
import { connect } from "react-redux";
import {
  insertagentenquiry,
  updateagentenquiry,
  getcitystate,
  getallagentsenquiry,
  deleteagentenquiry
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";
//import GridGlobalActions from "../common/ag-grid/GridGlobalActions";
import Select from "react-select";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class Enquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      cityid: 0,
      stateid: 0,
      selectedcity: {},
      selectedstate: {},
      colDefs: [
        { field: "agentname", headerName: "Company" },
        { field: "address", headerName: "Address" },
        { field: "cityname", headerName: "City" },
        { field: "totalkeysissued", headerName: "Keys Issued" },
        { field: "totalkeysused", headerName: "Keys Used" },
        { field: "contactphone", headerName: "Phone" },
        { field: "username", headerName: "Username" },
        { field: "password", headerName: "Password" },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params",
          pinned: "right"
        }
      ],
      rowClassRules: {
        terminated: function(params) {
          return params.data.isterminated;
        }
      },
      rowData: [],
      context: { componentParent: this },
      showGridDelete: false,
      showGridEdit: false,
      showGridCustom1: true,
      showGridCustom1Lbl: "Approve",
      totalkeysissued: 0,
      totalkeysused: 0,
      files: [],
      username: "",
      password: "",
      contactname: "",
      showform: false
    };
  }

  componentDidMount = async () => {
    await this.props.getcitystate();
    await this.props.getallagentsenquiry();

    this.setState({ rowData: this.props.lstAgentsEnquiry });
  };

  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  };

  statechange = async selectedOption => {
    await this.setState({
      stateid: selectedOption.value,
      cityid: 0,
      selectedstate: selectedOption
    });

    var a = this.props.lstcitystate.city.filter(
      item => item.state_id === selectedOption.value
    )[0];
    this.setState({ selectedcity: a });
  };

  citychange = async selectedOption => {
    await this.setState({
      cityid: selectedOption.value,
      selectedcity: selectedOption
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    //var path = [];

    // this.pond.getFiles().map(item => {
    //   var a = JSON.parse(item.serverId);

    //   path.push(a[0].path);
    // });

    const formdata = {
      id: this.state.id,
      agentname: this.state.agentname,
      address: this.state.address,
      stateid: this.state.stateid,
      cityid: this.state.cityid,
      ownername: this.state.ownername,
      contactname: this.state.ownername,
      contactemail: this.state.contactemail,
      contactphone: this.state.contactphone,
      files: this.state.files,
      filepaths: "",
      username: this.state.username,
      password: this.state.password
    };
    if (this.state.id <= 0) await this.props.insertagentenquiry(formdata);
    else await this.props.updateagentenqiry(formdata);

    await this.props.getallagentsenquiry();

    await this.setState({
      id: 0,
      agentname: "",
      address: "",
      ownername: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      files: []
    });
  };

  agGridEdit = async row => {
    await this.setState({
      id: row.id,
      agentname: row.agentname,
      address: row.address,
      stateid: row.stateid,
      cityid: row.cityid,
      ownername: row.ownername,
      contactname: row.contactname,
      contactemail: row.contactemail,
      contactphone: row.contactphone
    });

    var sel = this.props.lstcitystate.state.filter(
      item => item.value === row.stateid
    )[0];

    await this.setState({ selectedstate: sel });
    sel = this.props.lstcitystate.city.filter(
      item => item.value === row.cityid
    )[0];
    await this.setState({ selectedcity: sel });
  };

  agGridDelete = row => {
    this.props.deleteagentenquiry({ id: row.id });
  };

  agGridCustom1 = row => {
    this.props.terminateagent({ id: row.id });
  };

  handleInit() {
    //console.log("FilePond instance has initialised", this.pond);
  }
  server = e => {
    alert();
  };

  render() {
    return (
      // <div className="single-widget-container">
      //   <div className="row">
      //     <div className="col-md-6">
      //       <div className="widget">
      //         <div className="widget-body">
      //           </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>

      <div className="bg-contact100">
        <div className="container-contact100">
          <div className="wrap-contact100">
            {/* <div className="contact100-pic js-tilt" data-tilt>
              <h2>
                MDTS
                <h6> Get in touch with us.</h6>
              </h2>
            </div> */}
            <form onSubmit={this.handleSubmit}>
              <div className="widget-padding-md border rounded">
                <div className="row">
                  <div className="col-md-12">
                    <h4>MDTS - Contactus</h4>
                  </div>
                  <hr />
                  <div className="col-md-12 form-group">
                    <label>Company Name</label>
                    <input
                      id="agentname"
                      name="agentname"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter agent"
                      onChange={this.onInputChange}
                      required
                      value={this.state.agentname}
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label>Address</label>
                    <textarea
                      rows={2}
                      id="address"
                      name="address"
                      className="form-control input-lg input"
                      placeholder="Enter Address"
                      onChange={this.onInputChange}
                      required
                      value={this.state.address}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>State</label>
                    <Select
                      className="input-select input-select"
                      classNamePrefix="select"
                      name="usertype"
                      defaultValue={this.state.stateid || 0}
                      options={this.props.lstcitystate.state}
                      value={this.state.selectedstate}
                      required
                      onChange={this.statechange}
                    />
                  </div>
                  {/* {this.props.lstcitystate.city !== undefined && ( */}
                  <div className="col-md-6">
                    <label>City</label>
                    <Select
                      defaultValue={this.state.cityid || 0}
                      className="input-select input-select"
                      classNamePrefix="select"
                      name="usertype"
                      options={
                        this.props.lstcitystate.city === undefined
                          ? []
                          : this.props.lstcitystate.city.filter(
                              item => item.state_id === this.state.stateid
                            ) //options={this.props.lstcitystate.city}
                      }
                      value={this.state.selectedcity}
                      required
                      onChange={this.citychange}
                    />
                  </div>
                  {/* )} */}

                  <div className="col-md-12 form-group">
                    <label>Owner Name</label>
                    <input
                      id="ownername"
                      name="ownername"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter Owner"
                      onChange={this.onInputChange}
                      required
                      value={this.state.ownername}
                    />
                  </div>
                  {/* <div className="col-md-6 form-group">
                    <label>Contact Name</label>
                    <input
                      id="contactname"
                      name="contactname"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter contact person "
                      onChange={this.onInputChange}
                      required
                      value={this.state.contactname}
                    />
                  </div> */}
                  <div className="col-md-6 form-group">
                    <label>Contact Email</label>
                    <input
                      id="contactemail"
                      name="contactemail"
                      type="email"
                      className="form-control input-lg input"
                      placeholder="Enter contact person "
                      onChange={this.onInputChange}
                      required
                      value={this.state.contactemail}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Contact Phone</label>
                    <input
                      id="contactphone"
                      name="contactphone"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter contact person "
                      onChange={this.onInputChange}
                      required
                      value={this.state.contactphone}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Username (leave blank for autogenerate)</label>
                    <input
                      id="username"
                      name="username"
                      type="email"
                      className="form-control input-lg input"
                      placeholder="Enter user name "
                      onChange={this.onInputChange}
                      value={this.state.username}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Password (leave blank for autogenerate)</label>
                    <input
                      id="password"
                      name="password"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter Password"
                      onChange={this.onInputChange}
                      value={this.state.password}
                    />
                  </div>
                </div>

                <div className="form-action bg-widget position-relative form-group">
                  <button className="btn btn-sm btn-success mr-xs ">
                    Save
                  </button>
                  <button className="btn btn-sm btn-danger">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, lstAgentsEnquiry, ui, lstcitystate }) {
  return { auth, lstAgentsEnquiry, ui, lstcitystate };
}
export default connect(
  mapStateToProps,
  {
    getallagentsenquiry,
    insertagentenquiry,
    getcitystate,
    deleteagentenquiry,
    updateagentenquiry
  }
)(Enquiry);
