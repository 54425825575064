export const FETCH_USER = "fetch_user";
export const LOGOUT = "logout";
export const FETCH_PROGRAMS = "fetch_programs";
export const FETCH_APPLICATIONLIST = "fetch_applicationlist";
export const CHECK_TOKEN = "check_token";
export const UPDATE_DATA = "update_data";
export const UPDATE_DATA_PURCHASE_FORM = "UPDATE_DATA_PURCHASE_FORM";
export const GET_DEPARTMENTS = "get_departments";
export const CLEAR_UPDATE = "clear_update";
export const LEFT_HAMBURGER = "left_hamburger";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const GET_CITY_STATE = "GET_CITY_STATE";
export const GET_STATE = "GET_STATE";
export const UPDATE_AGENT = "UPDATE_AGENT";
export const GENERATE_LICENCE_KEYS = "GENERATE_LICENCE_KEYS";
export const ADD_PAYMENT_DATA = "ADD_PAYMENT_DATA";
export const GET_USED_KEYS = "GET_USED_KEYS";
export const GET_ALL_LICENCEKEYS = "GET_ALL_LICENCEKEYS";
export const DELETE_KEYS = "DELETE_KEYS";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";

export const GET_AGGRID_CONFIG = "GET_AGGRID_CONFIG";

export const GET_QUESTION_ANSWER_ENGLISH = "GET_QUESTION_ANSWER_ENGLISH";

export const GET_ALL_AGENTS_ENQUIRY = "GET_ALL_AGENTS_ENQUIRY";
export const UPDATE_AGENT_ENQUIRY = "UPDATE_AGENT_ENQUIRY";
export const UPDATE_DATA_ENQUIRY = "UPDATE_DATA_ENQUIRY";

export const GET_DASHBOARD = "GET_DASHBOARD";

export const GET_ALL_PURCHASE_FORM = "GET_ALL_PURCHASE_FORM";

export const GET_RECEIPT_NO="GET_RECEIPT_NO";
export const GET_PENDING_INVOICES="GET_PENDING_INVOICES";
