import React, { Component } from "react";
import Modal from "react-responsive-modal";

class GridActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: this.props.context.componentParent.state.showGridEdit,
      delete: this.props.context.componentParent.state.showGridDelete,
      custom1: this.props.context.componentParent.state.showGridCustom1,
      custom1Lbl: this.props.context.componentParent.state.showGridCustom1Lbl,
      custom2: this.props.context.componentParent.state.showGridCustom2,
      custom2Lbl: this.props.context.componentParent.state.showGridCustom1Lb2,
      custom3: this.props.context.componentParent.state.showGridCustom3,
      custom3Lbl: this.props.context.componentParent.state.showGridCustom1Lb3,
      openmodal: false
    };
  }

  componentDidMount = async () => {
    if (
      this.props.context.componentParent.props.location.pathname === "/agent"
    ) {
      if (this.props.data.isterminated > 0) {
        await this.setState({
          custom1: false,
          custom2: true,
          custom2Lbl: "Continue"
        });
      }
    } else if (
      this.props.context.componentParent.props.location.pathname === "/enquiry"
    ) {
      if (this.props.data.isapproved > 0) {
        await this.setState({
          custom1: false
        });
      }
    }
    else if (
      this.props.context.componentParent.props.location.pathname === "/receipt"
    ) {
      if (this.props.data.completed > 0) {
        await this.setState({
          custom1: false
        });
      }
    }
  };
  handleDelete = () => {
    // confirmDialog(`Are you sure you want to continue?`)
    //   .then(() => {
    //     this.props.context.componentParent.agGridDelete(
    //       this.props.data,
    //       this.props.node.id
    //     );
    //   })
    //   .catch(() => {
    //     console.log("User canceled");
    //   });

    this.props.context.componentParent.agGridDelete(this.props.data);

    this.onCloseModal();

    // this.onOpenModal();
  };

  onOpenModal = () => {
    this.setState({ modelOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modelOpen: false });
  };

  handleEdit = () => {
    if (this.props.context.componentParent.agGridEdit) {
      this.props.context.componentParent.agGridEdit(
        this.props.data,
        this.props.node.id
      );
    }
  };
  handleCustom1 = () => {
    if (this.props.context.componentParent.agGridCustom1) {
      this.props.context.componentParent.agGridCustom1(
        this.props.data,
        this.props.node.id
      );
    }
  };

  handleCustom2 = () => {
    if (this.props.context.componentParent.agGridCustom2) {
      this.props.context.componentParent.agGridCustom2(
        this.props.data,
        this.props.node.id
      );
    }
  };

  handleCustom3 = () => {
    if (this.props.context.componentParent.agGridCustom3) {
      this.props.context.componentParent.agGridCustom3(
        this.props.data,
        this.props.node.id
      );
    }
  };

  render() {
    return (
      <div>
        <Modal
          open={this.state.modelOpen}
          onClose={this.onCloseModal}
          center
          classNames={{
            overlay: "comfirm_modal_opacity",
            modal: "comfirm_modal",
            transitionEnter: "transitionEnter,",
            transitionEnterActive: "transitionEnterActive",
            transitionExit: "transitionExitActive",
            transitionExitActive: "transitionExitActive,"
          }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h3>Delete Confirmation!!!</h3>
            </div>
            <div className="col-lg-12">
              <h5>You want to delete this file?</h5>
            </div>

            <div className="col-lg-12">
              <center>
                <button
                  type="submit"
                  className="btn btn-success btn-lg "
                  onClick={this.handleDelete}
                >
                  <i className="fa fa-check">&nbsp;</i>
                  Yes, Delete it!
                </button>
                {"  "}
                <button
                  type="button"
                  onClick={this.onCloseModal}
                  className="btn btn-danger btn-lg"
                >
                  <i className="fa fa-times">&nbsp;</i>
                  No
                </button>
              </center>
            </div>
          </div>
        </Modal>
        {this.state.delete && (
          <button
            onClick={this.onOpenModal}
            type="button"
            className="btn btn-danger btn-xs"
          >
            <i className="fa fa-trash" />
          </button>
        )}{" "}
        {this.state.edit && (
          <button
            onClick={this.handleEdit}
            type="button"
            className="btn btn-success btn-xs"
          >
            <i className="fa fa-edit" />
          </button>
        )}{" "}
        {this.state.custom1 && (
          <button
            onClick={this.handleCustom1}
            type="button"
            className="btn btn-primary btn-xs"
          >
            {this.state.custom1Lbl ? this.state.custom1Lbl : "	\u2630"}
          </button>
        )}{" "}
        {this.state.custom2 && (
          <button
            onClick={this.handleCustom2}
            type="button"
            className="btn btn-primary btn-xs"
          >
            {this.state.custom2Lbl ? this.state.custom2Lbl : "..."}
          </button>
        )}{" "}
        {this.state.custom3 && (
          <button
            onClick={this.handleCustom3}
            type="button"
            className="btn btn-primary btn-xs"
          >
            {this.state.custom3Lbl ? this.state.custom3Lbl : "..."}
          </button>
        )}
      </div>
    );
  }
}

export default GridActions;
