import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";

import {
  insertproduct,
  deleteproduct,
  getallproducts
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslot: "day",
      colDefs: [
        { field: "product", headerName: "Product" },
        { field: "quantity", headerName: "quantity" },
        { field: "rate", headerName: "rate" },
        { field: "total", headerName: "total" },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params"
        }
      ],
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: false,
      quantity: 0,
      rate: 0
    };
  }

  componentDidMount = async () => {
    await this.props.getallproducts();

    this.setState({ rowData: this.props.lstProduct });
  };

  onChange = async selectedOption =>
    await this.setState({ usertype: selectedOption.value });

  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleSubmit = e => {
    e.preventDefault();

    const userdata = {
      productname: this.state.productname,
      quantity: this.state.quantity,
      rate: this.state.rate,
      total: this.state.quantity * this.state.rate
    };
    // console.log(PurchaseDatas);
    this.props.insertproduct(userdata);
  };

  agGridDelete = row => {
    this.props.deleteproduct({ id: row.id });
  };
  render() {
    return (
      <section className="widget">
        <h3 className="card-title">
          Product Management{" "}
          <p className="card-category">{"Product Creation"}</p>
        </h3>
        <div className="widget-body">
          <div className="row">
            <div className="col-md-6">
              <h4>Create Product</h4>
              <div className="widget-padding-md border rounded">
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Product Name</label>

                    <input
                      id="productname"
                      name="productname"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter productname"
                      onChange={this.onInputChange}
                      value={this.state.productname}
                      required
                    />
                  </div>

                  <div className="col-md-4 form-group">
                    <label>Quantity</label>

                    <input
                      id="quantity"
                      name="quantity"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter quantity"
                      onChange={this.onInputChange}
                      required
                      value={this.state.quantity}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Rate</label>

                    <input
                      id="rate"
                      name="rate"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter rate"
                      onChange={this.onInputChange}
                      required
                      value={this.state.rate}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Amount</label>

                    <input
                      id="amount"
                      name="aamount"
                      type="text"
                      className="form-control input-lg input"
                      disabled
                      value={this.state.quantity * this.state.rate}
                    />
                  </div>
                </div>

                <div className="form-action bg-widget position-relative form-group">
                  <button
                    className="btn btn-sm btn-success mr-xs "
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                  <button className="btn btn-sm btn-danger">Cancel</button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h4>User List</h4>
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    rowData={this.props.lstProduct}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps({ auth, lstUsers, ui, lstProduct }) {
  return { auth, lstUsers, ui, lstProduct };
}

// const mapStateToProps() {
//   return {
//     user,
//     lstUsers
//   };
// };
export default connect(
  mapStateToProps,
  { getallproducts, insertproduct, deleteproduct }
)(Product);
