import axios from "axios";
import { FETCH_USER, LOGOUT, CHECK_TOKEN, GET_ALL_USERS } from "./types";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import notification from "../utils/notification";
const keys = require("../config/keys");

const logOutCurrentUser = function(dispatch) {
  delete axios.defaults.headers.common["token"];
  const res = {};
  res.redirectToLogin = true;
  res.logOut = true;
  dispatch({ type: LOGOUT, payload: res });
};

export const fetchUser = objUserDetails => async dispatch => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/login",
    data: {
      UserName: objUserDetails.username,
      Password: objUserDetails.password
    }
  })
    .then(res => {
      let auth = {};
      

      if (res.data.userDetails.length) {
        if (res.data.userDetails[0].isactive) {
          try {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userId", res.data.userDetails[0].id);
            localStorage.setItem("UserName", res.data.userDetails[0].username);
            localStorage.setItem("IsLoggedIn", true);
            localStorage.setItem(
              "userdetails",
              JSON.stringify(res.data.userDetails[0])
            );
            setAuthorizationToken(res.data.token);

            auth.userId = res.data.userDetails[0].id;
            auth.userType = res.data.userDetails[0].usertype;
            auth.showLoader = false;
            auth.logOut = false;
            auth.redirectToLogin = false;
            auth.redirectToApplication = true;
            auth.message = "Welcome user";
            auth.userdetail = res.data.userDetails[0];
            dispatch({ type: FETCH_USER, payload: auth });
            //createHistory.push('/applicationform');
          } catch (exc) {
            console.log("exception", exc);
          }
        } else if (!res.data.userDetails[0].IsActive) {
          auth.showLoader = false;
          auth.message = "Access Denied";
          dispatch({ type: FETCH_USER, payload: auth });
        }
      } else {
        auth.showLoader = false;
        auth.messagetype = "error";
        auth.message = "Invalid username or password";
        dispatch({ type: FETCH_USER, payload: auth });
        //notification("error", "Invalid", "Check your Username & Password");
      }
    })
    .catch(e => {
      let auth = {};
      auth.showLoader = false;
      auth.redirectToApplication = false;
      auth.logOut = false;
      auth.userId = 0;
      auth.messagetype = "error";
      auth.message = "Invalid username or password";
      dispatch({ type: FETCH_USER, payload: auth });
      notification("error", "Authentication", "Check your Username & Password");
    });
};

export const checkUserToken = (objTokenDetails, userType) => async dispatch => {
  // console.log(objTokenDetails);
  // setAuthorizationToken(objTokenDetails);
  await axios({
    method: "get",
    url: keys.ServicePath + "/login/api/chkToken",
    data: { token: objTokenDetails }
  })
    .then(res => {
      // const token = jwt_decode(objTokenDetails);
      if (!res.data.error) {
        setAuthorizationToken(res.data.token);

        const auth = {};
        auth.userId = res.data.data.Id;
        auth.userType = res.data.data.UserType;
        auth.warehouseId = res.data.data.WarehouseId;
        auth.username = res.data.data.UserName;
        auth.showLoader = false;
        auth.logOut = false;
        auth.redirectToLogin = false;
        auth.redirectToApplication = true;

        dispatch({ type: CHECK_TOKEN, payload: auth });
      } else {
        logOutCurrentUser(dispatch);
      }

      //console.log(res);
    })
    .catch(e => {
      logOutCurrentUser(dispatch);
      //console.log("catch error", JSON.stringify(e));
    });
};

export const getallusers = Id => async dispatch => {
  await axios({ method: "get", url: keys.ServicePath + "/api/getuserslist" })
    .then(res => {
      try {
        dispatch({ type: GET_ALL_USERS, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const logout = () => async dispatch => {
  //console.log("logout");
  window.sessionStorage.clear();
  window.localStorage.clear();
  logOutCurrentUser(dispatch);
};
