import { GET_AGGRID_CONFIG } from "../types";

export const calculateAgGrid = (pagination, uid) => async dispatch => {
  let gridHeight = document.documentElement.offsetHeight - 200;
  if (process.env.NODE_ENV === "development") {
    gridHeight = 446;
  }

  const gridBodyHeight = gridHeight - 105;
  const pageSize = parseInt(gridBodyHeight / 25);

  var gridConfig = {
    gridHeight,
    gridBodyHeight,
    pageSize,
    pagination: false,
    showExcelExport: true,
    showPrint: true,
    showReload: true
  };
  dispatch({ type: GET_AGGRID_CONFIG, payload: gridConfig });
  // await axios({
  //   method: "post",
  //   url: keys.ServicePath + "/masters/api/GetAllReportConfiguration",
  //   data: { UserId: uid || 966 }
  // })
  //   .then(res => {
  //     if (res.data.success) {
  //       try {
  //         gridConfig = {
  //           gridHeight,
  //           gridBodyHeight,
  //           pageSize: res.data.data.PageSize,
  //           pagination: res.data.data.Pagination ? true : false,
  //           showExcelExport: res.data.data.ShowExcelExport ? true : false,
  //           showPrint: res.data.data.ShowPrint ? true : false,
  //           showReload: res.data.data.ShowReload ? true : false
  //         };

  //         console.log("ag grid action : ", res.data.data);

  //       } catch (exc) {
  //         console.log("exception", exc);
  //       }
  //     }
  //   })
  //   .catch(e => {
  //     console.log("catch error", JSON.stringify(e));
  //   });

  // dispatch({ type: GET_AGGRID_CONFIG, payload: gridConfig });
};
