import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
//import axios from "axios";
import {
  insertagent,
  updateagent,
  getcitystate,
  getallagents,
  deleteagent,
  terminateagent
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";

//import GridGlobalActions from "../common/ag-grid/GridGlobalActions";
//import * as FilePond1 from 'filepond';
//import FilePondPluginGetFile from 'filepond-plugin-get-file';
import Select from "react-select";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
//import { unwatchFile } from "fs";

//import { downloadFileRequest } from "../../utils/api";
//FilePond1.registerPlugin(FilePondPluginGetFile);
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
var keys = require("../../config/keys");

// const pond = FilePond1.create({
//   name: 'filepond'
// });
// document.body.appendChild(pond.element);

const server = {
  url: keys.ServicePath,
  process: "/api/uploadimage",
  revert: "/api/deleteuploadimage",
  // remove: uniqueFileId => {
  //   console.log("delete", uniqueFileId);
  // },
  load: async (uniqueFileId, load, error) => {
    // console.log(uniqueFileId);
    //const { data } = await downloadFileRequest(uniqueFileId);

    //console.log(data);
    // axios({
    //   url: uniqueFileId,
    //   method: "GET",
    //   responseType: "blob" // important
    // }).then(response => {
    //   console.log(response);
    //   const url = window.URL.createObjectURL(new Blob([response.data]));

    //   load(url);
    //   // const link = document.createElement('a');
    //   // link.href = url;
    //   // link.setAttribute('download', 'file.pdf');
    //   // document.body.appendChild(link);
    //   // link.click();
    // });
    // var burl = null;
    // axios({
    //   method: "get",
    //   url: keys.ServicePath + "/api/getimageblob?fileid=" + uniqueFileId
    // }).then(response => {
    //   console.log("res", response);
    //   burl = window.URL.createObjectURL(new Blob([response.data]));

    //   console.log("url", burl);

    //   // const link = document.createElement('a');
    //   // link.href = url;
    //   // link.setAttribute('download', 'file.pdf');
    //   // document.body.appendChild(link);
    //   // link.click();
    // });
    // load(burl);
    //var proxyUrl = "https://cors-anywhere.herokuapp.com/";

    //   var myHeaders = new Headers();
    //   var myInit = {
    //     method: "GET",
    //     headers: myHeaders,
    //     mode: "cors",
    //     cache: "default"
    //   };

    //   var myRequest = new Request(uniqueFileId, myInit);
    ///http:/ / localhost: 5001 / uploads / yfrepmozwipdazqyqdxinhvchhbbrn.jpg
    fetch(uniqueFileId)
      .then(res => res.blob())
      .then(load)
      .catch(error);
  },
  fetch: (url, load, error, progress, abort, headers) => {}
};
class Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      cityid: 0,
      stateid: 0,
      selectedcity: {},
      selectedstate: {},
      showform: false,
      colDefs: [
        {
          field: "username",
          headerName: "Username",
          filter: "agTextColumnFilter"
        },

        {
          field: "agentname",
          headerName: "Company",
          filter: "agTextColumnFilter"
        },
        {
          field: "address",
          headerName: "Address",
          filter: "agTextColumnFilter"
        },
        {
          field: "statename",
          headerName: "State",
          filter: "agTextColumnFilter",
          width: 200
        },
        {
          field: "cityname",
          headerName: "City",
          filter: "agTextColumnFilter",
          width: 200
        },

        {
          field: "totalkeysissued",
          headerName: "Issued",
          filter: "agNumberColumnFilter",
          width: 120
        },
        {
          field: "totalkeysused",
          headerName: "Used",
          filter: "agNumberColumnFilter",
          width: 120
        },
        {
          field: "contactphone",
          headerName: "Phone",
          filter: "agTextColumnFilter"
        },
        {
          field: "password",
          headerName: "Password",
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params",
          pinned: "right"
        }
      ],
      rowClassRules: {
        terminated: function(params) {
          return params.data.isterminated > 0;
        }
      },
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: true,
      showGridCustom1: true,
      showGridCustom1Lbl: "Terminate",
      totalkeysissued: 0,
      totalkeysused: 0,
      contactname: "",
      // files: [],
      files: [],
      files1: [],
      file2: [],
      username: "",
      password: "",
      agentname: "",
      editmode: false,
      contactemail: "",
      file1: null,
      totalusers:0,
      allKeyIssued: 0,
      allKeyUsed: 0
    };
  }

  componentDidMount = async () => {
    await this.props.getcitystate();
    await this.props.getallagents();
    // console.log("this.props.lstAgents :: ",this.props.lstAgents)
    const allKeyIssued = await this.props.lstAgents.reduce((allKeyIssued, lstAgent) => allKeyIssued + lstAgent.totalkeysissued, 0);
    const allKeyUsed = await this.props.lstAgents.reduce((allKeyUsed, lstAgent) => allKeyUsed + lstAgent.totalkeysused, 0);
    this.setState({ rowData: this.props.lstAgents, allKeyIssued, allKeyUsed,totalusers:this.props.lstAgents.length });
  };

  onInputChange = async e => {
   
    await this.setState({ [e.target.name]: e.target.value });
    
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    //params.columnApi.autoSizeColumns();
  };

  statechange = async selectedOption => {
    await this.setState({
      stateid: selectedOption.value,
      cityid: 0,
      selectedstate: selectedOption
    });

    var a = this.props.lstcitystate.city.filter(
      item => item.state_id === selectedOption.value
    )[0];
    this.setState({ selectedcity: a });
  };

  citychange = async selectedOption => {
    await this.setState({
      cityid: selectedOption.value,
      selectedcity: selectedOption
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    var path = [];
    try {
      this.pond.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch {
      path.push(this.state.files[0].name);
    }

    try {
      this.pond1.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch {
      path.push(this.state.files1[0].name);
    }

    try {
      this.pond2.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch {
      path.push(this.state.file2[0].name);
    }

    const formdata = {
      id: this.state.id,
      agentname: this.state.agentname,
      address: this.state.address,
      stateid: this.state.stateid,
      cityid: this.state.cityid,
      ownername: this.state.ownername,
      gstno: this.state.gstno,
      contactname: this.state.contactname,
      contactemail: this.state.contactemail,
      contactphone: this.state.contactphone,
      files: this.state.files,
      filepaths: path.toString(),
      username: this.state.username,
      password: this.state.password,
      editmode: false
    };
    if (this.state.id <= 0) await this.props.insertagent(formdata);
    else await this.props.updateagent(formdata);

    await this.props.getallagents();

    await this.setState({
      id: 0,
      agentname: "",
      address: "",
      ownername: "",
      gstno: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      username: "",
      password: "",
      files: [],
      files1: [],
      file2: [],
      editmode: false,
      showform: false
    });
  };

  agGridEdit = async row => {
    await this.setState({
      id: row.id,
      agentname: row.agentname,
      address: row.address,
      stateid: row.stateid,
      cityid: row.cityid,
      ownername: row.ownername,
      gstno: row.gstno,
      contactname: row.contactname,
      contactemail: row.contactemail,
      contactphone: row.contactphone,
      totalkeysissued: row.totalkeysissued,
      totalkeysused: row.totalkeysused,
      username: row.username,
      password: row.password,
      //files1: row.imagePaths.split(","),
      // files: row.imagePaths.split(","),
      editmode: true,
      showform: true
    });

    var imgP = [];
    row.imagePaths.split(",").map((item, index) => {
      imgP = [];
      imgP.push(keys.ServicePath + "/" + item);
      if (index === 0) {
        //imgP.push(keys.ServicePath + "/" + item);

        this.setState({ files: imgP });
      } else if (index === 1) {
        this.setState({ files1: imgP });
      } else if (index === 2) {
        this.setState({ file2: imgP });
      }
      return "";
    });

    var sel = this.props.lstcitystate.state.filter(
      item => item.value === row.stateid
    )[0];

    await this.setState({ selectedstate: sel });
    sel = this.props.lstcitystate.city.filter(
      item => item.value === row.cityid
    )[0];
    await this.setState({ selectedcity: sel });
  };

  addnew = () => this.setState({ showform: true });

  agGridDelete = row => {
    this.props.deleteagent({ id: row.id });
  };

  agGridCustom1 = row => {
    this.props.terminateagent({ id: row.id });
  };

  agGridCustom2 = row => {
    this.props.terminateagent({ id: row.id });
  };

  handleInit() {
    //console.log("FilePond instance has initialised", this.pond);
  }

  server = e => {
    alert();
  };

  handleChange = event => {
    
    this.setState({
      file1: URL.createObjectURL(event.target.files[0])
    });
    //console.log(URL.createObjectURL(event.target.files[0]));
  };


  onFilterModified =async params => {
    params.api.redrawRows();
    
     const allKeyIssued = await params.api.rowModel.rowsToDisplay.reduce((allKeyIssued, lstAgent) => allKeyIssued + lstAgent.data.totalkeysissued, 0);
     const allKeyUsed = await params.api.rowModel.rowsToDisplay.reduce((usedkeys, lstAgent) => usedkeys + lstAgent.data.totalkeysused, 0);
     this.setState({allKeyIssued,allKeyUsed,totalusers:params.api.rowModel.rowsToDisplay.length})

    // paramsAPI.forEachNodeAfterFilterAndSort((node, index) => {
    //   objPropList.forEach(arrProps => {
    //     if (node.data.hasOwnProperty(arrProps)) {
    //       footerRowObj[arrProps] =
    //         footerRowObj[arrProps] + node.data[arrProps];
    //     }
    //   });
    // });
    
    //this.getCustomPinnedRows(params.api);
  };

  render() {
    return (
      <div className="widget">
        <h3 className="card-title">
          <div className="row">
            <div className="col-md-3">
              Agent
              <p className="card-category">{"Register Agents/Distrubutor's"}</p>
            </div>
            <div className="col-md-9 text-right">
              <span className='headerText'>Total Users : <strong>{this.state.totalusers}</strong></span> 
              <span className='headerText'>Total Keys : <strong>{this.state.allKeyIssued}</strong></span> 
              <span className='headerText'>Total Keys Used : <strong>{this.state.allKeyUsed}</strong></span>
            </div>
          </div>
        </h3>

        <div className="widget-body">
          <div className="row">
            {this.state.showform && (
              <div className="col-md-6">
                <h4>Create Company/Distibutor's</h4>
                <form onSubmit={this.handleSubmit}>
                  <div className="widget-padding-md border rounded">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Company Name</label>
                        <input
                          id="agentname"
                          name="agentname"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter agent"
                          onChange={this.onInputChange}
                          required
                          value={this.state.agentname}
                        />
                      </div>
                      <div className="col-md-12 form-group">
                        <label>Address</label>
                        <textarea
                          rows={2}
                          id="address"
                          name="address"
                          className="form-control input-lg input"
                          placeholder="Enter Address"
                          onChange={this.onInputChange}
                          required
                          value={this.state.address}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>State</label>
                        <Select
                          className="input-select input-select"
                          classNamePrefix="select"
                          name="usertype"
                          defaultValue={this.state.stateid || 0}
                          options={this.props.lstcitystate.state}
                          value={this.state.selectedstate}
                          required
                          onChange={this.statechange}
                        />
                      </div>
                      {this.props.lstcitystate.city !== undefined && (
                        <div className="col-md-6">
                          <label>City</label>
                          <Select
                            defaultValue={this.state.cityid || 0}
                            className="input-select input-select"
                            classNamePrefix="select"
                            name="usertype"
                            options={
                              this.props.lstcitystate.city.filter(
                                item => item.state_id === this.state.stateid
                              ) //options={this.props.lstcitystate.city}
                            }
                            value={this.state.selectedcity}
                            required
                            onChange={this.citychange}
                          />
                        </div>
                      )}

                      <div className="col-md-6 form-group">
                        <label>
                          Total Keys issued : {this.state.totalkeysissued}
                        </label>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>
                          Total Keys Used : {this.state.totalkeysused}
                        </label>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Owner Name</label>
                        <input
                          id="ownername"
                          name="ownername"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter Owner"
                          onChange={this.onInputChange}
                          required
                          value={this.state.ownername}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>GST No</label>
                        <input
                          id="gstno"
                          name="gstno"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter GST No"
                          onChange={this.onInputChange}
                          required
                          value={this.state.gstno}
                        />
                      </div>
                      {/* <div className="col-md-6 form-group">
                      <label>Contact Name</label>
                      <input
                        id="contactname"
                        name="contactname"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter contact person "
                        onChange={this.onInputChange}
                        required
                        value={this.state.contactname}
                      />
                    </div> */}
                      <div className="col-md-6 form-group">
                        <label>Contact Email</label>
                        <input
                          id="contactemail"
                          name="contactemail"
                          type="email"
                          className="form-control input-lg input"
                          placeholder="Enter contact person "
                          onChange={this.onInputChange}
                          value={this.state.contactemail}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Contact Phone/Mobile</label>
                        <input
                          id="contactphone"
                          name="contactphone"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter contact person "
                          onChange={this.onInputChange}
                          required
                          value={this.state.contactphone}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Username (leave blank for autogenerate)</label>
                        <input
                          id="username"
                          name="username"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter user name "
                          onChange={this.onInputChange}
                         // disabled={this.state.editmode}
                          value={this.state.username}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Password (leave blank for autogenerate)</label>
                        <input
                          id="password"
                          name="password"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter Password"
                          onChange={this.onInputChange}
                          value={this.state.password}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <FilePond
                          name={"file"}
                          ref={ref => (this.pond = ref)}
                          allowMultiple={false}
                          accept="image/png, image/jpeg, image/gif"
                          maxFiles={3}
                          server={
                            server //server={keys.ServicePath + "/api/uploadimage"}
                          }
                          oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            this.setState({
                              files: fileItems.map(fileItem => fileItem.file)
                            });
                          }}
                          fileValidateTypeLabelExpectedTypesMap={{
                            "image/jpeg": ".jpg"
                          }}
                          labelFileTypeNotAllowed={
                            "Upload JPEG files allowed ."
                          }
                          allowFileSizeValidation={true}
                          maxTotalFileSize={10485760}
                          onremovefile={file => {}}
                          //allowImagePreview={false}
                          //imagePreviewHeight={100}
                        >
                          {this.state.files.map((file, index) => {
                            
                            return (
                              <File key={index} src={file} origin="local" />
                            );
                          })}
                        </FilePond>
                        {
                          this.state.files.length > 0 && 
                          <a href={(Array.isArray(this.state.files) && this.state.files[0].name)?keys.ServicePath+'/'+this.state.files[0].name:''} download={this.state.files[0].name} target="_blank" rel="noopener noreferrer">Download 1</a>
                        }
                        
                      </div>
                      <div className="col-md-4 form-group">
                        <FilePond
                          name={"file"}
                          ref={ref => (this.pond1 = ref)}
                          allowMultiple={false}
                          accept="image/png, image/jpeg, image/gif"
                          maxFiles={3}
                          server={
                            server //server={keys.ServicePath + "/api/uploadimage"}
                          }
                          oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            this.setState({
                              files1: fileItems.map(fileItem => fileItem.file)
                            });
                          }}
                          fileValidateTypeLabelExpectedTypesMap={{
                            "image/jpeg": ".jpg"
                          }}
                          labelFileTypeNotAllowed={
                            "Upload JPEG files allowed ."
                          }
                          allowFileSizeValidation={true}
                          maxTotalFileSize={10485760}
                          onremovefile={file => {}}
                          //allowImagePreview={false}
                          //imagePreviewHeight={100}
                        >
                          {this.state.files1.map((file, index) => {
                            return (
                              <File key={index} src={file} origin="local" />
                            );
                          })}
                        </FilePond>
                        {
                          this.state.files1.length > 0 && 
                          <a href={(Array.isArray(this.state.files1) && this.state.files1[0].name)?keys.ServicePath+'/'+this.state.files1[0].name:''} download={this.state.files1[0].name} target="_blank" rel="noopener noreferrer">Download 2</a>
                        }
                      </div>
                      <div className="col-md-4 form-group">
                        <FilePond
                          name={"file"}
                          ref={ref => (this.pond2 = ref)}
                          allowMultiple={false}
                          accept="image/png, image/jpeg, image/gif"
                          maxFiles={3}
                          server={
                            server //server={keys.ServicePath + "/api/uploadimage"}
                          }
                          oninit={() => this.handleInit()}
                          onupdatefiles={fileItems => {
                            this.setState({
                              file2: fileItems.map(fileItem => fileItem.file)
                            });
                          }}
                          fileValidateTypeLabelExpectedTypesMap={{
                            "image/jpeg": ".jpg"
                          }}
                          labelFileTypeNotAllowed={
                            "Upload JPEG files allowed ."
                          }
                          allowFileSizeValidation={true}
                          maxTotalFileSize={10485760}
                          onremovefile={file => {}}
                          //allowImagePreview={false}
                          //imagePreviewHeight={100}
                        >
                          {this.state.file2.map((file, index) => (
                            <File key={index} src={file} origin="local" />
                          ))}
                        </FilePond>
                        {
                          this.state.file2.length > 0 && 
                          <a href={(Array.isArray(this.state.file2) && this.state.file2[0].name)?keys.ServicePath+'/'+this.state.file2[0].name:''} download={this.state.file2[0].name} target="_blank" rel="noopener noreferrer">Download 3</a>
                        }
                      </div>
                    </div>

                    <div className="form-action bg-widget position-relative form-group">
                      <button className="btn btn-sm btn-success mr-xs ">
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => this.setState({ showform: false })}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}{" "}
            <div className={this.state.showform ? "col-md-6" : "col-md-12"}>
              <h4>
                {/* Agent List */}
                <button className="btn btn-success" onClick={this.addnew}>
                  <i className="fa fa-plus" />
                  {"  Add New"}
                </button>
              </h4>

              <div className="widget-padding-md border rounded">
                {/* <GridGlobalActions /> */}
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    rowData={this.props.lstAgents}
                    rowClassRules={this.state.rowClassRules}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                    onFilterModified={this.onFilterModified}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, lstAgents, ui, lstcitystate }) {
  return { auth, lstAgents, ui, lstcitystate };
}
export default connect(
  mapStateToProps,
  {
    getallagents,
    insertagent,
    getcitystate,
    deleteagent,
    updateagent,
    terminateagent
  }
)(Agent);
