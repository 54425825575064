import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class QuestionBank extends Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <h3 className="page-title">
          Questions - <span className="fw-semi-bold">{"Question Bank"}</span>
        </h3>
        <div className="row">
          <div className="col-md-12">
            <section className="widget">
              <div className="widget-body">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      uploadCallback: this.uploadFile
                    }
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionBank;
