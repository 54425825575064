import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { getusedkeys } from "../../actions/user";
import DatePicker from 'react-date-picker';
const moment = require("moment");

class UsedKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colDefs: [
        { field: "batchcode", headerName: "Batch Code", width: 120 },
        {
          field: "licencekey",
          headerName: "Licence Keys",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          width: 120
        },
        { field: "usedon", headerName: "Usedon", width: 100 },
        {
          field: "agentname",
          headerName: "Agent Name",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          width: 250
          // filter: "agSetColumnFilter"
        },
        {
          field: "city",
          headerName: "City",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          width: 100
          // filter: "agSetColumnFilter"
        },
        {
          field: "name",
          headerName: "Customer Name",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          width: 200
          // filter: "agSetColumnFilter"
        },
        {
          field: "mobile",
          headerName: "Mobile",
          width: 120
          // filter: "agSetColumnFilter"
        },
        {
          field: "email",
          headerName: "Email",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          width: 200
          // filter: "agSetColumnFilter"
        }
      ],
      defaultColDef: { filter: "agTextColumnFilter" },
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: false,
      showGridCustom2: true,
      showGridCustom1: true,
      showGridCustom1Lb2: "Print",
      showGridCustom1Lbl: "Excel",
      licencekeys: [],
      modelOpen: false,
      showlicencekeygrid: false,
      keysUsed:0,
      // startDate: new Date(),
      // endDate: new Date(),
      excelStyles: [
        {
          id: "used",
          interior: {
            color: "#FF0000",
            pattern: "Solid"
          }
        }
      ]
    };
  }

  componentDidMount = async () => {
    await this.props.getusedkeys();
    
    const allKeyUsed = await this.props.lstusedkeys.length;
    this.setState({keysUsed:allKeyUsed});

  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //params.api.sizeColumnsToFit();
  };

  onStartDateChange = startDate => {
    this.setState({ startDate })
  }
  onEndDateChange = endDate => {
    this.setState({ endDate })
  }
  
  handleFilter = async e => {
    e.preventDefault();
    this.props.getusedkeys(moment(this.state.startDate).format("YYYY-MM-DD"),moment(this.state.endDate).format("YYYY-MM-DD"));
    const allKeyUsed = await this.props.lstusedkeys.length;
    this.setState({keysUsed:allKeyUsed});
  };

  showAllData = async e => {
    e.preventDefault();
    this.setState({ startDate:'', endDate:'' })
    await this.props.getusedkeys();
    const allKeyUsed = await this.props.lstusedkeys.length;
    this.setState({keysUsed:allKeyUsed});
  };
  onFilterModified = params => {
    params.api.redrawRows();
    this.setState({keysUsed:params.api.rowModel.rowsToDisplay.length});
    
    //this.getCustomPinnedRows(params.api);
  };

  render() {
    return (
      <section className="widget">
        <div className="col-md-2">
          <h3 className="card-title">
            Used Key Details{" "}
            <p className="card-category">{"Used Licencekey details"}</p>
          </h3>
        </div>
        <div className="col-md-10">
          <div className="col-md-12">
            <div className="col-md-3 form-group">
              <label>Start Date : </label> 
              <DatePicker
                onChange={this.onStartDateChange}
                value={this.state.startDate} 
                format='y-MM-dd'
              />
            </div>
            <div className="col-md-3 form-group">
              <label>End Date : </label> 
              <DatePicker
                onChange={this.onEndDateChange} 
                value={this.state.endDate} 
                format='y-MM-dd'
              />
            </div>
            <div className="col-md-6 form-group">
                <button
                  className="btn btn-sm btn-success mr-xs "
                  onClick={this.handleFilter}
                >
                  Show Data
                </button>
                <button
                  className="btn btn-sm btn-success mr-xs "
                  onClick={this.showAllData}
                >
                  Show All Data
                </button>
            </div>
    <span className="keys">{"TOTAL KEY USED : "}<span className="green_font_bold">{this.state.keysUsed}</span></span>
          </div>
        </div>

        <div className="widget-body">
          <div className="row">
            <div className="col-md-12">
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.lstusedkeys}
                    onSelectionChanged={this.selectedrow}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                    onFilterModified={this.onFilterModified}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps({ auth, ui, lstusedkeys }) {
  return { auth, ui, lstusedkeys };
}

export default connect(
  mapStateToProps,
  {
    getusedkeys
  }
)(UsedKeys);
