import React, { Component } from "react";
import { connect } from "react-redux";
// import { AgGridReact } from "ag-grid-react";
import {
  getalllicencekeysById,
  generatelicencekeys,
  addPaymentData,
  getallproducts,
  payment,
  redirectpayment
} from "../../actions/user";
import Select from "react-select";

//const gPay = require("../common/googlePay");
const randomstring = require("randomstring");

class LicKeyGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showform: true,
      codecount: 0,
      keylength: 8,
      lickey: [],
      batch: "",
      keys: "",
      rowDatakeys: [],
      colDefs: [
        { field: "batchcode", headerName: "Batch Code" },
        {
          field: "lkey",
          headerName: "Licence Keys",
          cellClass: "cell-wrap-text"
        },
        { field: "lkeycount", headerName: "Keys count" },
        { field: "createddatetime", headerName: "Created-on" }
      ],
      defaultColDef: { filter: "agTextColumnFilter" },
      rowData: [],
      context: { componentParent: this },
      showGridEdit: false,
      showGridCustom2: false,
      showGridCustom1: false,
      licencekeys: [],
      selectedProduct: {}
    };
  }

  componentDidMount = async () => {

    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    await this.props.getalllicencekeysById(userdetails.disributorid);
    await this.setState({
      contactphone: this.props.lstlickeys[0].contactphone
    });
    if (userdetails.usertype === "distributor") {
      await this.props.getallproducts({ showFreeProducts: false });
    } else await this.props.getallproducts();

    this.generatebatchno();


  // this.props.redirectpayment();
    // await gPay.onGooglePayLoaded();
    // const paymentsClient = gPay.getGooglePaymentsClient();
    // const button = paymentsClient.createButton({
    //   onClick: this.onGooglePaymentButtonClicked
    // });
    // document.getElementById("gPayContainer").appendChild(button);
  };

  // onGooglePaymentButtonClicked = async e => {
  //   const paymentDataRequest = gPay.getGooglePaymentDataRequest();
  //   paymentDataRequest.transactionInfo = gPay.getGoogleTransactionInfo();

  //   let gPayment = "";
  //   const paymentsClient = gPay.getGooglePaymentsClient();
  //   await paymentsClient
  //     .loadPaymentData(paymentDataRequest)
  //     .then(async function(paymentData) {
  //       gPayment = paymentData;
  //     })
  //     .catch(function(err) {
  //       console.error("google pay err : ", err);
  //     });

  //   if (gPayment !== "") {
  //     this.handleSubmit(e, gPayment);
  //   }
  // };

  generatebatchno = () => {
    var batch = randomstring.generate({ length: 9, charset: "numeric" });
    this.setState({ batch: batch });
  };

  onProductChange = async selectedOption => {
    
    await this.setState({
      productid: selectedOption.value,
      codecount: selectedOption.quantity,
      selectedProduct: selectedOption
    });
    this.generateCode();
  };

  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  // handleSubmit = async (e, gPayment) => {
  //   // console.log('paymentData : ',gPayment)
  //   e.preventDefault();
  //   const userdetails = JSON.parse(localStorage.getItem("userdetails"));

  //   const formdata = {
  //     agentid: userdetails.disributorid,
  //     productid: this.state.productid,
  //     batchno: this.state.batch,
  //     licencekeys: this.state.lickey,
  //     contactphone: this.state.contactphone
  //   };
  //   await this.props.generatelicencekeys(formdata);

  //   const payFormdata = {
  //     agentid: userdetails.disributorid,
  //     productid: this.state.productid,
  //     batchno: this.state.batch,
  //     keylength: this.state.lickey.length,
  //     total: 2,
  //     token: gPayment.paymentMethodData.tokenizationData.token,
  //     cardNetwork: gPayment.paymentMethodData.info.cardNetwork,
  //     cardNo: gPayment.paymentMethodData.info.cardDetails,
  //     paymentType: gPayment.paymentMethodData.type
  //   };
  //   // console.log('payFormdata : ',payFormdata)
  //   await this.props.addPaymentData(payFormdata);

  //   // await this.props.getalllicencekeysById(userdetails.disributorid);
  //   // await this.generatebatchno();

  //   // this.setState({ lickey: [], keys: "" });

  //   this.props.history.push(`/mykeys`);
  // };

  generateCode = () => {
    if (this.state.codecount > 0) {
      const s = new Set();
      while (s.size < this.state.codecount) {
        s.add(
          randomstring.generate({
            length: this.state.keylength,
            charset: "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789",
            capitalization: "uppercase"
          })
        );
      }

      this.setState({
        lickey: [...s],
        keys: ' "' + [...s].join('","') + '"'
      });
    }
  };

  selectedrow = () => {
    var row = this.gridApi.getSelectedRows();
    this.setState({
      rowDatakeys: row[0].licencedetails,
      bcode: row[0].batchcode,
      documentname: row[0].agentnamewithcity,
      batchcode: row[0].batchcode
    });
  };

  
  paymentClick = () => {
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    var batch = randomstring.generate({ length: 9, charset: "numeric" });
    const formdata = {
      agentid: userdetails.disributorid,
      productid: this.state.selectedProduct.value,
      batchno: batch,
      licencekeys: this.state.lickey,
      contactphone: this.state.contactphone
    };

   // await this.props.generatelicencekeys(formdata);


    
    let orderInfo = {};
    let payRequest = {};
    orderInfo = {
      user: userdetails.username,
      product: this.state.selectedProduct.label
    };

    payRequest = {
      id: 0,
     vpc_Amount:parseFloat(this.state.selectedProduct.rate) *   parseFloat(this.state.selectedProduct.quantity),
     //vpc_Amount:1,
      vpc_MerchTxnRef:
        userdetails.username +
        "|" +
        userdetails.contactphone +
        "|" +
        this.state.selectedProduct.label,
      vpc_OrderInfo: JSON.stringify(orderInfo),
      vpc_Version: 1,
      vpc_OrderInfo1: JSON.stringify(orderInfo),
      vpc_firstname: userdetails.contactname,
      vpc_email: userdetails.contactemail,
      vpc_phone: userdetails.contactphone,
      vpc_udf1: this.state.selectedProduct.value,
      vpc_udf2: userdetails.disributorid,
      vpc_udf3: batch,
      vpc_udf4: this.state.lickey.length,
      vpc_udf5:this.state.lickey,
      vpc_udf6: "2",
      vpc_lastName: "",
      formdata
    };

    this.props.payment(payRequest);
  };

  render() {
    return (
      <section className="widget">
        <h3 className="card-title">Purchase Key</h3>
        <div className="widget-body">
          <div className="row">
            <div className="col-md-6">
              <div className="widget-padding-md border rounded">
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Product</label>
                    <Select
                      className="input-select"
                      classNamePrefix="select"
                      name="product"
                      onChange={this.onProductChange}
                      options={this.props.lstProduct.map(item => {
                        return {
                          value: item.id,
                          label: item.product,
                          rate: item.rate,
                          quantity: item.quantity
                        };
                      })}
                      required
                    />
                  </div>

                  <div className="col-md-4 form-group">
                    {/* <label>Batch No : </label> */}
                    <input
                      id="batchno"
                      name="batchno"
                      type="hidden"
                      className="form-control input-lg input"
                      placeholder="RTOKL0001"
                      required
                      defaultValue={this.state.batch}
                    />
                  </div>

                  <div className="col-md-4 form-group">
                    {/* <label>Lic. Key Length </label> */}
                    <input
                      id="keylength"
                      name="keylength"
                      type="hidden"
                      className="form-control input-lg input"
                      placeholder="Enter no of codes to generate"
                      onChange={this.onInputChange}
                      required
                      value={this.state.keylength}
                    />
                  </div>

                  <div className="col-md-4 form-group">
                    {/* <label>No. of code to generate </label> */}
                    <input
                      id="codecount"
                      name="codecount"
                      type="hidden"
                      className="form-control input-lg input"
                      placeholder="Enter no of codes to generate"
                      onChange={this.onInputChange}
                      value={this.state.codecount}
                      required
                      onBlur={this.generateCode}
                    />
                  </div>

                  {/* <div className="col-md-12 form-group">
                    <label>Generated Key</label>
                    <textarea
                      rows={3}
                      value={this.state.keys}
                      className="form-control input-lg input"
                      disabled
                    />
                  </div> */}
                </div>

                <div className="form-action bg-widget position-relative form-group">
                  {/* <div className="btn btn-sm mr-xs " id="gPayContainer"></div> */}
                  {/* <button
                    className="btn btn-sm btn-success mr-xs "
                    onClick={this.handleSubmit}
                  >
                    Purchase
                  </button> */}
                  <button
                    className="btn btn-lg btn-success mr-xs"
                    type="button"
                    onClick={this.paymentClick}
                  >
                    Proceed to Payment
                  </button>
                  <button
                    className="btn btn-lg btn-danger"
                    type="button"
                    onClick={() =>
                      this.setState({
                        lickey: [],
                        keys: ""
                      })
                    }
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.lstlickeys}
                    onSelectionChanged={this.selectedrow}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );  
  }
}

function mapStateToProps({ auth, lstlickeys, ui, lstProduct }) {
  return { auth, ui, lstlickeys, lstProduct };
}

export default connect(mapStateToProps, {
  getalllicencekeysById,
  generatelicencekeys,
  addPaymentData,
  getallproducts,
  payment,
  redirectpayment
})(LicKeyGenerator);
