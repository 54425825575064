import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import {
  insertconfiguration,
  deleteexamconfig,
  getallexamconfiguration,
  getstate
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

var keys = require("../../config/keys");

const server = {
  url: keys.ServicePath,
  process: "/api/uploadimage",
  revert: "/api/deleteuploadimage",
  load: async (uniqueFileId, load, error) => {    
    fetch(uniqueFileId)
      .then(res => res.blob())
      .then(load)
      .catch(error);
  },
  fetch: (url, load, error, progress, abort, headers) => {}
};

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateid: 0,
      selectedstate: {},
      colDefs: [
        { field: "statename", headerName: "State", width: 100 },
        { field: "totalquestions", headerName: "Questions", width: 100 },
        {
          field: "passingquestion",
          headerName: "Passing Question",
          width: 100
        },
        { field: "timelimit", headerName: "Time Limit", width: 100 },
        { field: "message", headerName: "Message" },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params"
        }
      ],
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: false,
      refresh: false
    };
  }

  componentDidMount = async () => {
    //await this.props.getallproducts();
    await this.props.getstate();
    await this.props.getallexamconfiguration();
  };

  onChange = async selectedOption =>
    await this.setState({ usertype: selectedOption.value });

  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  handleSubmit = e => {
    e.preventDefault();
    

    var path = [];
    try {
      this.pond.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch {
      path.push(this.state.files[0].name);
    }

    try {
      this.pond1.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch {
      path.push(this.state.files1[0].name);
    }

    const userdata = {
      stateid: this.state.stateid,
      statename: this.state.selectedstate.label,
      totalquestions: this.state.totalquestions,
      passingquestion: this.state.passingquestion,
      timelimit: this.state.timelimit,
      message: this.state.message,
      filepaths: path.toString()
    };
    this.props.insertconfiguration(userdata);
  };

  agGridDelete = row => {
    this.props.deleteexamconfig({ id: row.id });
  };

  statechange = async selectedOption => {
    await this.setState({
      stateid: selectedOption.value,
      selectedstate: selectedOption
    });
  };

  handleInit() {
    //console.log("FilePond instance has initialised", this.pond);
  }

  render() {
    return (
      <section className="widget">
        <h3 className="card-title">
          Configurations
          <p className="card-category">{""}</p>
        </h3>
        <div className="widget-body">
          <div className="row">
            <div className="col-md-6">
              <h4>Set Configuration</h4>
              <div className="widget-padding-md border rounded">
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>State</label>
                    <Select
                      className="input-select input-select"
                      classNamePrefix="select"
                      name="usertype"
                      //defaultValue={this.state.stateid || 0}
                      options={this.props.lststate}
                      value={this.state.selectedstate}
                      required
                      onChange={this.statechange}
                    />
                    {/* {this.props.lstcitystate && (
                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="usertype"
                        //defaultValue={this.state.stateid || 0}
                        options={this.props.lstcitystate}
                        value={this.state.selectedstate}
                        required
                        onChange={this.statechange}
                      />
                    )} */}
                  </div>

                  <div className="col-md-4 form-group">
                    <label>Total Question</label>

                    <input
                      id="totalquestions"
                      name="totalquestions"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter Total Questions"
                      onChange={this.onInputChange}
                      required
                      value={this.state.totalquestions}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>Passing Questions</label>

                    <input
                      id="passingquestion"
                      name="passingquestion"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Enter Passing Question"
                      onChange={this.onInputChange}
                      required
                      value={this.state.passingquestion}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>Time Limit</label>

                    <input
                      id="timelimit"
                      name="timelimit"
                      type="text"
                      className="form-control input-lg input"
                      onChange={this.onInputChange}
                      value={this.state.timelimit}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>Image 1</label>

                    <FilePond
                      name={"file"}
                      ref={ref => (this.pond = ref)}
                      allowMultiple={false}
                      accept="image/png, image/jpeg, image/gif"
                      maxFiles={3}
                      server={
                        server //server={keys.ServicePath + "/api/uploadimage"}
                      }
                      oninit={() => this.handleInit()}
                      onupdatefiles={fileItems => {
                        // Set currently active file objects to this.state
                        this.setState({
                          files: fileItems.map(fileItem => fileItem.file)
                        });
                      }}
                      fileValidateTypeLabelExpectedTypesMap={{"image/jpeg": ".jpg"}}
                      labelFileTypeNotAllowed={"Upload JPEG files allowed ."}
                      allowFileSizeValidation={true}
                      maxTotalFileSize={10485760}
                      onremovefile={file => {}} 
                      allowImagePreview={false}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>Image 2</label>

                    <FilePond
                      name={"file"}
                      ref={ref => (this.pond1 = ref)}
                      allowMultiple={false}
                      accept="image/png, image/jpeg, image/gif"
                      maxFiles={3}
                      server={
                        server //server={keys.ServicePath + "/api/uploadimage"}
                      }
                      oninit={() => this.handleInit()}
                      onupdatefiles={fileItems => {
                        // Set currently active file objects to this.state
                        this.setState({
                          files: fileItems.map(fileItem => fileItem.file)
                        });
                      }}
                      fileValidateTypeLabelExpectedTypesMap={{"image/jpeg": ".jpg"}}
                      labelFileTypeNotAllowed={"Upload JPEG files allowed ."}
                      allowFileSizeValidation={true}
                      maxTotalFileSize={10485760}
                      onremovefile={file => {}} 
                      allowImagePreview={false}
                    />
                  </div>

                  <div className="col-md-4 form-group">
                      <label>Message</label>
                      <textarea
                        rows={2}
                        id="message"
                        name="message"
                        className="form-control input-lg input"
                        placeholder="Enter Message"
                        onChange={this.onInputChange}
                        required
                        value={this.state.message}
                      />
                  </div>
                
                </div>

                <div className="form-action bg-widget position-relative form-group">
                  <button
                    className="btn btn-sm btn-success mr-xs "
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                  <button className="btn btn-sm btn-danger">Cancel</button>
                </div>
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    rowData={this.props.lstProduct}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6" />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps({ auth, lstUsers, ui, lstProduct, lststate }) {
  return { auth, lstUsers, ui, lstProduct, lststate };
}

// const mapStateToProps() {
//   return {
//     user,
//     lstUsers
//   };
// };
export default connect(
  mapStateToProps,
  { getallexamconfiguration, insertconfiguration, deleteexamconfig, getstate }
)(Configuration);
