import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import { AgGridReact } from "ag-grid-react";
//import d from "../Dashboard/data.json";
import { getDashboard } from "../../actions/user";
import {
  HighchartsChart,
  withHighcharts,
  Title,
  Subtitle,
  XAxis,
  YAxis,
  TreemapSeries,
  Tooltip
} from "react-jsx-highcharts";
import addTreemapModule from "highcharts/modules/treemap";
addTreemapModule(Highcharts);
const formatData = data => {

  
  const colours = Highcharts.getOptions().colors;
  const formattedData = [];
  const unique = [...new Set(data.map(item => item.state))];
  unique.map((regionName, rIndex) => {
    //console.log("rgname", regionName);
    const region = {
      id: `id_${rIndex}`,
      name: regionName,
      color: colours[rIndex]
    };

    let regionSum = 0;

    //const state = Object.keys(data[regionName]);
    var state = data.filter(innerdata => innerdata.state === regionName);

    state.forEach((cities, cIndex) => {
      const city = {
        id: `${region.id}_${cIndex}`,
        name: cities.city,
        parent: region.id
      };
      formattedData.push(city);
      let cause = {
        id: `${city.id}_0`,
        name: "Total Keys Issued",
        parent: city.id,
        value: Math.round(parseFloat(cities.total))
      };
      formattedData.push(cause);
      cause = {
        id: `${city.id}_1`,
        name: "Keys Used",
        parent: city.id,
        value: Math.round(parseFloat(cities.used))
      };
      formattedData.push(cause);
      // console.log(formattedData);
      // Object.keys(data[regionName][countryName]).forEach((causeName, index) => {
      //   const cause = {
      //     id: `${country.id}_${index}`,
      //     name: causeName,
      //     parent: country.id,
      //     value: Math.round(
      //       parseFloat(data[regionName][countryName][causeName])
      //     )
      //   };
      //   formattedData.push(cause);
      //   regionSum += cause.value;
      // });
      regionSum += parseFloat(cities.total);
    });

    region.value = Math.round(regionSum);
    return formattedData.push(region);
  });

  return formattedData;
};

class DashboardMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslot: "day",
      gridData: [],
      colDefs: [
        {
          field: "state",
          headerName: "State",
          filter: "agTextColumnFilter"
        },

        {
          field: "city",
          headerName: "City",
          filter: "agTextColumnFilter"
        },
        {
          field: "total",
          headerName: "Total",
          filter: "agTextColumnFilter",
          width: 100
        },
        {
          field: "used",
          headerName: "Used",
          filter: "agTextColumnFilter",
          width: 100
        }
      ]
      //bgColor: "red"
    };
  }
  componentDidMount = async () => {
    //  console.log(d);
    var userdetails = JSON.parse(localStorage.getItem("userdetails"));
     await this.props.getDashboard(userdetails.disributorid);
   
    let totalKeys = this.props.dashboardDetails.reduce(
      (a, b) => a + (b["total"] || 0),
      0
    );
    let usedKeys = this.props.dashboardDetails.reduce(
      (a, b) => a + (b["used"] || 0),
      0
    );
    this.setState({
      treeData: formatData(this.props.dashboardDetails),
      gridData: this.props.dashboardDetails,
      totalKeys: totalKeys,
      usedKeys: usedKeys
    });
  };

  render() {
    const treeData = this.state.treeData;
    if (!treeData) return null;

    const levels = [
      {
        level: 1,
        dataLabels: {
          enabled: true
        },
        borderWidth: 3
      }
    ];
    const tooltipFormatter = function() {
      return `${this.key}: ${this.point.value}`;
    };

    return (
      <div className="widget">
        <div className="row">
          <div className="col-md-6">
            <HighchartsChart>
              <Title>
                {"Keys Distributed (Total Keys :" +
                  this.state.totalKeys +
                  " | Used Keys : " +
                  this.state.usedKeys +
                  ") "}
              </Title>

              <Subtitle>Click points to drill down. Source: WHO.</Subtitle>

              <XAxis />

              <YAxis>
                <TreemapSeries
                  data={treeData}
                  allowDrillToNode
                  layoutAlgorithm="squarified"
                  animationLimit={1000}
                  dataLabels={{ enabled: false }}
                  levelIsConstant={false}
                  levels={levels}
                />
              </YAxis>

              <Tooltip formatter={tooltipFormatter} />
            </HighchartsChart>
          </div>
          <div className="col-md-6">
            <div
              className="ag-theme-balham"
              style={{ height: 500, width: "100%" }}
            >
              <AgGridReact
                columnDefs={this.state.colDefs}
                rowData={this.props.dashboardDetails}
                floatingFilter={true}
                enableSorting={true}
                pagination={this.props.ui.ag_grid_ui.pagination}
                paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, dashboardDetails, ui }) {
  return { auth, dashboardDetails, ui };
}
export default withHighcharts(
  connect(
    mapStateToProps,
    { getDashboard }
  )(DashboardMaster),
  Highcharts
);
