import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import {
  generatelicencekeys,
  getalllicencekeysById,
  getallagents,
  getallproducts,
  getAllPurchaseForm
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";
//import Select from "react-select";
import Modal from "react-responsive-modal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

const randomstring = require("randomstring");

class AgentKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codecount: 0,
      keylength: 5,
      lickey: [],
      batch: "",
      keys: "",
      colDefs: [
        // {
        //   field: "agentname",
        //   headerName: "Agent Name",
        //   filter: "agSetColumnFilter"
        // },
        {
          field: "batchcode",
          headerName: "Batch Code",
          width: 150,
          filter: "agTextColumnFilter"
        },
        {
          field: "lkey",
          headerName: "Licence Keys",
          //autoHeight: true,
          cellClass: "cell-wrap-text"
        },
        { field: "lkeycount", headerName: "Keys count", width:70 },
        { field: "keysused", headerName: "Used Keys", width:70 },
        { field: "createddatetime", headerName: "Created-on" },
        {
          headerName: "Actions",
          width: 150,
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params",
          pinned: "right"
        }
      ],
      colDefskeys: [
        // {
        //   field: "agentname",
        //   headerName: "Agent Name",
        //   filter: "agSetColumnFilter"
        // },
        //{ field: "batchcode", headerName: "Batch Code", width: 150 },
        {
          field: "licencekey",
          headerName: "Licence Keys",
          cellClassRules: {
            used: function(params) {
              return params.data.isused === 1;
            }
          }
        },
        {
          field: "usedon",
          headerName: "Used On",
          cellClassRules: {
            used: function(params) {
              return params.data.isused === 1;
            }
          }
        },
        {
          field: "name",
          headerName: "Name",
          cellClassRules: {
            used: function(params) {
              return params.data.isused === 1;
            }
          }
        },
        {
          field: "mobile",
          headerName: "Mobile",
          cellClassRules: {
            used: function(params) {
              return params.data.isused === 1;
            }
          }
        },
        {
          field: "email",
          headerName: "Email",
          cellClassRules: {
            used: function(params) {
              return params.data.isused === 1;
            }
          }
        }
      ],
      // rowClassRules: {
      //   used: "data.isused >0"
      // },
      defaultColDef: { filter: "agTextColumnFilter" },
      defaultColDefkeys: { filter: "agTextColumnFilter" },
      rowData: [],
      rowDatakeys: [],
      context: { componentParent: this },
      showGridDelete: false,
      showGridEdit: false,
      showGridCustom1: true,
      showGridCustom1Lbl: "Print Invoice",
      showGridCustom2: true,
      showGridCustom1Lb2: "Print Keys",
      licencekeys: [],
      modelOpen: false,
      modelOpen1: false,
      loader: false,
      resultEmpty: false,
      bcode: "",
      excelStyles: [
        {
          id: "used",
          interior: {
            color: "#FF0000",
            pattern: "Solid"
          }
        }
      ]
    };
  }

  componentDidMount = async () => {
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    await this.props.getalllicencekeysById(userdetails.disributorid);
    await this.setState({
      contactphone: this.props.lstlickeys[0].contactphone
    });
    if (userdetails.id > 1) {
      await this.props.getallagents(userdetails.disributorid);
    } else {
      await this.props.getallagents();
    }
    await this.props.getallproducts();
    this.generatebatchno();
  };

  generatebatchno = () => {
    var batch = randomstring.generate({ length: 9, charset: "numeric" });

    this.setState({ batch: batch });
  };
  onChange = async selectedOption =>
    await this.setState({ agentid: selectedOption.value });
  onProductChange = async selectedOption => {
    await this.setState({
      productid: selectedOption.value,
      codecount: selectedOption.quantity
    });
    this.generateCode();
  };
  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // params.api.sizeColumnsToFit();
  };
  onGridReadykeys = params => {
    this.gridApikeys = params.api;
    this.gridColumnApikeys = params.columnApi;
    //params.api.sizeColumnsToFit();
  };

  handleSubmit = async e => {
    e.preventDefault();

    const formdata = {
      agentid: this.state.agentid,
      productid: this.state.productid,
      batchno: this.state.batch,
      licencekeys: this.state.lickey,
      contactphone: this.state.contactphone
    };

    await this.props.generatelicencekeys(formdata);
    await this.props.getalllicencekeys(formdata);
    await this.generatebatchno();

    this.setState({ agentid: 0, lickey: [], keys: "" });
  };

  agGridDelete = row => {
    this.props.deleteuser({ id: row.id });
  };

  agGridCustom2 = async row => {
    await this.setState(
      {
        detaillicencekeys: row.licencedetails, // row.lkey.split(","),
        documentname: row.agentnamewithcity
      },
      () => {
        this.setState({ modelOpen: true });
      }
    );
  };

  agGridCustom1 = async row => {
    // console.log("print row", row);
    await this.setState({ loader: true });
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));
    await this.props.getAllPurchaseForm({
      agentId: userdetails.disributorid,
      batchCode: row.batchcode
    });
    // console.log('Res == ',this.props.listPurchaseForm)

    if (this.props.listPurchaseForm.length > 0) {
      await this.setState(
        {
          agentname: this.props.listPurchaseForm[0].agentname,
          address: this.props.listPurchaseForm[0].address,
          cityname: this.props.listPurchaseForm[0].cityname,
          statename: this.props.listPurchaseForm[0].statename,
          stateid: this.props.listPurchaseForm[0].stateid,
          contactemail: this.props.listPurchaseForm[0].contactemail,
          contactphone: this.props.listPurchaseForm[0].contactphone,
          date: this.props.listPurchaseForm[0].createddatetime,
          quantity: this.props.listPurchaseForm[0].quantity,
          rate: this.props.listPurchaseForm[0].rate,
          total: this.props.listPurchaseForm[0].total,
          product: this.props.listPurchaseForm[0].product,
          gstno: this.props.listPurchaseForm[0].gstno,
          batchCode: row.batchcode,
          resultEmpty: false
        },
        () => {
          this.setState({ modelOpen1: true, loader: false });
        }
      );
    } else {
      this.setState({ modelOpen1: true, loader: false, resultEmpty: true });
    }
  };

  onCloseModal = () => {
    this.setState({ modelOpen: false, modelOpen1: false });
  };
  generateCode = () => {
    if (this.state.codecount > 0) {
      const s = new Set();
      while (s.size < this.state.codecount) {
        s.add(
          randomstring.generate({
            length: this.state.keylength,
            charset: "alphanumeric",
            capitalization: "uppercase"
          })
        );
      }

      this.setState({
        lickey: [...s],
        keys: ' "' + [...s].join('","') + '"'
      });
    }
  };

  printDocument = row => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save(this.state.documentname + "_keys.pdf");
    });
  };

  excelexport = () => {
    //var gridApi = this.props.context.gridApikeys;

    //console.log(gridApi);
    var params = {
      exportMode: "xlsx",
      allColumns: true,
      fileName: this.state.documentname + ".xlsx",
      sheetName: "Licence_keys"
    };
    this.gridApikeys.exportDataAsExcel(params);
  };
  selectedrow = () => {
    var row = this.gridApi.getSelectedRows();
    // console.log(row);
    this.setState({
      rowDatakeys: row[0].licencedetails,
      bcode: row[0].batchcode,
      documentname: row[0].agentnamewithcity
    });
  };
  render() {
    return (
      <section className="widget">
        {this.state.loader ? (
          <div className="overlay">
            <div id="loader"></div>
          </div>
        ) : (
          ""
        )}

        <h3 className="card-title">
          My Keys{" "}
          <p className="card-category">{"List of purchased item and keys"}</p>
        </h3>
        <div className="widget-body">
          <div className="row">
            <div className="col-md-8">
              <h4>List of Keys</h4>
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.lstlickeys}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                    onSelectionChanged={this.selectedrow}
                    rowSelection={"single"}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <h4>{"Keys list (Batch no : " + this.state.bcode + ")"}</h4>
              <div className="widget-padding-md border rounded">
                <button
                  className="btn btn-sm btn-success mr-xs "
                  onClick={this.excelexport}
                >
                  <i className="fa fa-file-excel-o" /> {" Excel Export"}
                </button>
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefskeys}
                    defaultColDef={this.state.defaultColDefkeys}
                    rowData={this.state.rowDatakeys}
                    onGridReady={this.onGridReadykeys}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                    excelStyles={this.state.excelStyles}
                    // rowClassRules={this.state.rowClassRules}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={this.state.modelOpen} onClose={this.onCloseModal} center>
          <div className="row">
            <div className="col-lg-12" id="divToPrint">
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="h6LicenceKey">{this.state.documentname}</h3>
                  <hr className="ruler" />
                </div>

                {this.state.detaillicencekeys &&
                  this.state.detaillicencekeys.map((item, index) => {
                    return (
                      <div key={index} className="col-md-2">
                        <h6
                          className={
                            item.isused === 1
                              ? "h6LicenceKey red"
                              : "h6LicenceKey"
                          }
                        >
                          {item.licencekey}
                        </h6>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-12">
              <button
                className="btn btn-sm btn-success mr-xs "
                onClick={this.printDocument}
              >
                <i className="fa fa-print" /> Print
              </button>
            </div>
          </div>
        </Modal>

        <Modal open={this.state.modelOpen1} onClose={this.onCloseModal} center>
          <div className="row">
            <div className="col-lg-12">
              <button
                className="btn btn-sm btn-success mr-xs "
                onClick={this.printDocument}
              >
                <i className="fa fa-print" /> Print
              </button>
            </div>

            <div className="col-lg-12">
              <div className="row invoice-body" id="divToPrint">
                <section>
                  <header className="clearfix">
                    <div id="logo">
                      <img
                        alt="logo"
                        src="http://localhost:3000/static/media/favicon.7645d66a.png"
                      />
                    </div>
                    <div id="company">
                      <h2 className="name">BHAVESH AJMERI</h2>
                      <div>G-3, Shiv Chambers, Sardar Bhavan Lane,</div>
                      <div>Raopura, Vadodara - 390001. Gujarat. (India)</div>
                      <div>
                        9426376678 |&nbsp;
                        <a href="mailto:bhavesh456ajmeri@gmail.com">
                          bhavesh456ajmeri@gmail.com
                        </a>
                      </div>
                    </div>
                  </header>

                  {this.state.resultEmpty ? (
                    <main className="text-center">No data found</main>
                  ) : (
                    <main>
                      <div id="details" className="clearfix">
                        <div id="client">
                          <h2 className="name">M/s. {this.state.agentname}</h2>
                          <div className="address">
                            {this.state.address}, {this.state.cityname},{" "}
                            {this.state.statename}
                          </div>
                          <div className="email">
                            <a href={this.state.contactemail}>
                              {this.state.contactemail}
                            </a>
                          </div>
                        </div>
                        <div id="invoice">
                          <h1>Invoice No # {this.state.batchCode}</h1>
                          <div className="date">GST No: {this.state.gstno}</div>
                          <div className="date">
                            Date of Invoice: {this.state.date}
                          </div>
                        </div>
                      </div>
                      <table
                        className="borderTable"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th className="no">#</th>
                            <th className="desc">Particulars</th>
                            <th className="unit">Qty.</th>
                            <th className="qty">Rate</th>
                            <th className="total">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="no">01</td>
                            <td className="desc">
                              <h3>MDTS Book</h3>
                            </td>
                            <td className="unit">{this.state.quantity}</td>
                            <td className="qty">&#x20b9;{this.state.rate}</td>
                            <td className="total">
                              &#x20b9;{this.state.total}{" "}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot border="0">
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">SUBTOTAL</td>
                            <td>
                              &#x20b9;
                              {this.state.total - (5 * this.state.total) / 100}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">TAX 5%</td>
                            <td>&#x20b9;{(5 * this.state.total) / 100}</td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">GRAND TOTAL</td>
                            <td>&#x20b9;{this.state.total}</td>
                          </tr>
                        </tfoot>
                      </table>

                      {this.state.totalInWords &&
                      this.state.totalInWords !== "" ? (
                        <div id="invoice">
                          Amount Chargeable (in words) :{" "}
                          {this.state.totalInWords}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.stateid && this.state.stateid === 12 ? (
                        <table
                          className="taxTable taxTable1"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="desc">
                                CGST
                              </th>
                              <th colSpan="2" className="unit">
                                SGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="desc">Rate (%)</td>
                              <td className="desc">Amt.</td>
                              <td className="unit">Rate (%)</td>
                              <td className="unit">Amt.</td>
                            </tr>

                            <tr>
                              <td className="no">
                                &#x20b9;
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="desc">2.5</td>
                              <td className="desc">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                              <td className="unit">2.5</td>
                              <td className="unit">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table
                          className="taxTable"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="qty">
                                IGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="qty">Rate (%)</td>
                              <td className="qty">Amt.</td>
                            </tr>
                            <tr>
                              <td className="no">
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="qty">5</td>
                              <td className="qty">
                                &#x20b9;{(5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      <table className="taxTable" width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <b>Terms & Condition : </b>Subject to{" "}
                              {this.state.cityname} Jurisdiction
                            </td>
                            <td>
                              <b>Payment Mode : </b>Cash
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div id="thanks">Thank you!</div> */}
                      <div id="notices">
                        <div className="notice">
                          NOTICE : IGST will be Count only Out of Gujarat Sale.
                          Otherwise Filled remain Blank.
                        </div>
                      </div>
                    </main>
                  )}

                  <div>
                    --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </div>

                  <header className="clearfix">
                    <div id="logo">
                      <img
                        alt="logo"
                        src="http://localhost:3000/static/media/favicon.7645d66a.png"
                      />
                    </div>
                    <div id="company">
                      <h2 className="name">BHAVESH AJMERI</h2>
                      <div>G-3, Shiv Chambers, Sardar Bhavan Lane,</div>
                      <div>Raopura, Vadodara - 390001. Gujarat. (India)</div>
                      <div>
                        9426376678 |&nbsp;
                        <a href="mailto:bhavesh456ajmeri@gmail.com">
                          bhavesh456ajmeri@gmail.com
                        </a>
                      </div>
                    </div>
                  </header>
                  {this.state.resultEmpty ? (
                    <main className="text-center">No data found</main>
                  ) : (
                    <main>
                      <div id="details" className="clearfix">
                        <div id="client">
                          <h2 className="name">M/s. {this.state.agentname}</h2>
                          <div className="address">
                            {this.state.address}, {this.state.cityname},{" "}
                            {this.state.statename}
                          </div>
                          <div className="email">
                            <a href={this.state.contactemail}>
                              {this.state.contactemail}
                            </a>
                          </div>
                        </div>
                        <div id="invoice">
                          <h1>Invoice No # {this.state.batchCode}</h1>
                          <div className="date">
                            Date of Invoice: {this.state.date}
                          </div>
                          <div className="date">
                            Date: {moment().format("DD/MM/YY")}
                          </div>
                        </div>
                      </div>
                      <table
                        className="borderTable"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th className="no">#</th>
                            <th className="desc">Particulars</th>
                            <th className="unit">Qty.</th>
                            <th className="qty">Rate</th>
                            <th className="total">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="no">01</td>
                            <td className="desc">
                              <h3>MDTS Book</h3>
                            </td>
                            <td className="unit">{this.state.quantity}</td>
                            <td className="qty">&#x20b9;{this.state.rate}</td>
                            <td className="total">
                              &#x20b9;{this.state.total}{" "}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot border="0">
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">SUBTOTAL</td>
                            <td>
                              &#x20b9;
                              {this.state.total - (5 * this.state.total) / 100}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">TAX 5%</td>
                            <td>&#x20b9;{(5 * this.state.total) / 100}</td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">GRAND TOTAL</td>
                            <td>&#x20b9;{this.state.total}</td>
                          </tr>
                        </tfoot>
                      </table>

                      {this.state.totalInWords &&
                      this.state.totalInWords !== "" ? (
                        <div id="invoice">
                          Amount Chargeable (in words) :{" "}
                          {this.state.totalInWords}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.stateid && this.state.stateid === 12 ? (
                        <table
                          className="taxTable taxTable1"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="desc">
                                CGST
                              </th>
                              <th colSpan="2" className="unit">
                                SGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="desc">Rate (%)</td>
                              <td className="desc">Amt.</td>
                              <td className="unit">Rate (%)</td>
                              <td className="unit">Amt.</td>
                            </tr>

                            <tr>
                              <td className="no">
                                &#x20b9;
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="desc">2.5</td>
                              <td className="desc">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                              <td className="unit">2.5</td>
                              <td className="unit">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table
                          className="taxTable"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="qty">
                                IGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="qty">Rate (%)</td>
                              <td className="qty">Amt.</td>
                            </tr>
                            <tr>
                              <td className="no">
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="qty">5</td>
                              <td className="qty">
                                &#x20b9;{(5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      <table className="taxTable" width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <b>Terms & Condition : </b>Subject to{" "}
                              {this.state.cityname} Jurisdiction
                            </td>
                            <td>
                              <b>Payment Mode : </b>Cash
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div id="thanks">Thank you!</div> */}
                      <div id="notices">
                        <div className="notice">
                          NOTICE : IGST will be Count only Out of Gujarat Sale.
                          Otherwise Filled remain Blank.
                        </div>
                      </div>
                    </main>
                  )}
                </section>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

function mapStateToProps({
  auth,
  lstlickeys,
  ui,
  lstAgents,
  lstProduct,
  listPurchaseForm
}) {
  return { auth, ui, lstlickeys, lstAgents, lstProduct, listPurchaseForm };
}

// const mapStateToProps() {
//   return {
//     user,
//     lstUsers
//   };
// };
export default connect(mapStateToProps, {
  generatelicencekeys,
  getalllicencekeysById,
  getallagents,
  getallproducts,
  getAllPurchaseForm
})(AgentKeys);
