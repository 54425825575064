import React, { Component } from "react";
import { connect } from "react-redux";

import {
  insertagent,
  updateagent,
  getcitystate,
  getallagents,
  deleteagent,
  terminateagent
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";
//import GridGlobalActions from "../common/ag-grid/GridGlobalActions";
import Select from "react-select";
import { FilePond, File, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

var keys = require("../../config/keys");
const server = {
  url: keys.ServicePath,
  process: "/api/uploadimage",
  revert: "/api/deleteuploadimage",
  load: (uniqueFileId, load, error) => {
    fetch(uniqueFileId)
      .then(res => res.blob())
      .then(load)
      .catch(error);
  }
};
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      cityid: 0,
      stateid: 0,
      selectedcity: {},
      selectedstate: {},
      colDefs: [
        { field: "agentname", headerName: "Company" },
        { field: "address", headerName: "Address" },
        { field: "cityname", headerName: "City" },
        { field: "totalkeysissued", headerName: "Keys Issued" },
        { field: "totalkeysused", headerName: "Keys Used" },
        { field: "contactphone", headerName: "Phone" },
        { field: "username", headerName: "Username" },
        { field: "password", headerName: "Password" },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params",
          pinned: "right"
        }
      ],
      rowClassRules: {
        terminated: function(params) {
          //console.log(params.data.prgConfigMstId);
          return params.data.isterminated > 0;
        }
      },
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: true,
      showGridCustom1: true,
      showGridCustom1Lbl: "Terminate",
      totalkeysissued: 0,
      totalkeysused: 0,
      files: [],
      files1: [],
      files2: [],
      tmpfiles: [],
      username: "",
      password: "",
      agentname: "",
      image1: "",
      image2: "",
      image3: "",
      contactname: "",
      gstno:'',
      refresh: false
    };
  }

  componentDidMount = async () => {
    var userdetails = JSON.parse(localStorage.getItem("userdetails"));

    
    await this.props.getcitystate();
    await this.props.getallagents(userdetails.disributorid);

    const result = this.props.lstAgents.filter(item => {
      return item.id === userdetails.disributorid;
    });
   // console.log(result)
    this.agGridEdit(result[0]);

    this.setState({ rowData: result });
  };

  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  };

  statechange = async selectedOption => {
    await this.setState({
      stateid: selectedOption.value,
      cityid: 0,
      selectedstate: selectedOption
    });

    var a = this.props.lstcitystate.city.filter(
      item => item.state_id === selectedOption.value
    )[0];
    this.setState({ selectedcity: a });
  };

  citychange = async selectedOption => {
    await this.setState({
      cityid: selectedOption.value,
      selectedcity: selectedOption
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    // var path = [];

    // this.pond.getFiles().map(item => {
    //   var a = JSON.parse(item.serverId);
    //   return path.push(a[0].path);
    // });

    var path = [];

    try {
      await this.pond.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch (ex) {
      path.push(this.state.files[0].name);
    }

    try {
      await this.pond1.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch (ex) {
      path.push(this.state.files1[0].name);
    }

    try {
      await this.pond2.getFiles().map(item => {
        var a = JSON.parse(item.serverId);
        return path.push(a[0].path);
      });
    } catch (ex) {
      path.push(this.state.files2[0].name);
    }
    

    const formdata = {
      id: this.state.id,
      agentname: this.state.agentname,
      address: this.state.address,
      stateid: this.state.stateid,
      cityid: this.state.cityid,
      ownername: this.state.ownername,
      contactname: this.state.ownername,
      gstno:this.state.gstno,
      contactemail: this.state.contactemail,
      contactphone: this.state.contactphone,
      files: this.state.files,
      filepaths: path.toString(),
      username: this.state.username,
      password: this.state.password
    };

    
    if (this.state.id <= 0) await this.props.insertagent(formdata);
    else await this.props.updateagent(formdata);

    await this.props.getallagents();

    // await this.setState({
    //   id: 0,
    //   agentname: "",
    //   address: "",
    //   stateid: 0,
    //   cityid: 0,
    //   ownername: "",
    //   contactname: "",
    //   contactemail: "",
    //   contactphone: "",
    //   files: []
    // });
  };

  agGridEdit = async row => {
    await this.setState({
      id: row.id,
      agentname: row.agentname,
      address: row.address,
      stateid: row.stateid,
      cityid: row.cityid,
      ownername: row.ownername,
      gstno:row.gstno,
      contactname: row.contactname,
      contactemail: row.contactemail,
      contactphone: row.contactphone,
      totalkeysissued: row.totalkeysissued,
      totalkeysused: row.totalkeysused,
      username: row.username,
      password: row.password
    });

    var sel = this.props.lstcitystate.state.filter(
      item => item.value === row.stateid
    )[0];

    await this.setState({ selectedstate: sel });
    sel = this.props.lstcitystate.city.filter(
      item => item.value === row.cityid
    )[0];
    await this.setState({ selectedcity: sel });
    var imgP = [];
    var timg = [];
    row.imagePaths.split(",").map((item, index) => {
      imgP = [];
      timg.push(keys.ServicePath + "/" + item);
      imgP.push(keys.ServicePath + "/" + item);
      if (index === 0) {
        this.setState({ files: imgP });
        this.setState({ image1: imgP });
      } else if (index === 1) {
        this.setState({ files1: imgP });
        this.setState({ image2: imgP });
      } else if (index === 2) {
        this.setState({ files2: imgP });
        this.setState({ image3: imgP });
      }
      return "";
    });

    this.setState({ tmpfiles: timg });
  };
  agGridDelete = row => {
    this.props.deleteagent({ id: row.id });
  };
  agGridCustom1 = row => {
    this.props.terminateagent({ id: row.id });
  };
  agGridCustom2 = row => {
    this.props.terminateagent({ id: row.id });
  };
  handleInit() {
    //console.log("FilePond instance has initialised", this.pond);
  }
  server = e => {
    alert();
  };

  render() {
    return (
      <div className="widget">
        {/* <h3 className="card-title">
          Agent
          <p className="card-category">{"Register Agents/Distrubutor's"}</p>
        </h3> */}

        <div className="widget-body">
          <div className="row">
            <form onSubmit={this.handleSubmit}>
              <div className="col-md-6">
                <h4>My Profile</h4>

                <div className="widget-padding-md border rounded">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label>Company Name</label>
                      <input
                        id="agentname"
                        name="agentname"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter agent"
                        onChange={this.onInputChange}
                        required
                        disabled={true}
                        value={this.state.agentname}
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      <label>Address</label>
                      <textarea
                        rows={2}
                        id="address"
                        name="address"
                        className="form-control input-lg input"
                        placeholder="Enter Address"
                        onChange={this.onInputChange}
                        required
                        value={this.state.address}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>State</label>
                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="usertype"
                        defaultValue={this.state.stateid || 0}
                        options={this.props.lstcitystate.state}
                        value={this.state.selectedstate}
                        required
                        onChange={this.statechange}
                      />
                    </div>
                    {this.props.lstcitystate.city !== undefined && (
                      <div className="col-md-6">
                        <label>City</label>
                        <Select
                          defaultValue={this.state.cityid || 0}
                          className="input-select input-select"
                          classNamePrefix="select"
                          name="usertype"
                          options={
                            this.props.lstcitystate.city.filter(
                              item => item.state_id === this.state.stateid
                            ) //options={this.props.lstcitystate.city}
                          }
                          value={this.state.selectedcity}
                          required
                          onChange={this.citychange}
                        />
                      </div>
                    )}

                    <div className="col-md-6 form-group">
                      <label>
                        Total Keys issued : {this.state.totalkeysissued}
                      </label>
                    </div>
                    <div className="col-md-6 form-group">
                      <label>
                        Total Keys Used : {this.state.totalkeysused}
                      </label>
                    </div>
                    <div className="col-md-12 form-group">
                      <label>Owner Name</label>
                      <input
                        id="ownername"
                        name="ownername"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter Owner"
                        onChange={this.onInputChange}
                        required
                        value={this.state.ownername}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>GST No</label>
                        <input
                          id="gstno"
                          name="gstno"
                          type="text"
                          className="form-control input-lg input"
                          placeholder="Enter GST No"
                          onChange={this.onInputChange}
                          value={this.state.gstno}
                        />
                      </div>
                    {/* <div className="col-md-6 form-group">
                      <label>Contact Name</label>
                      <input
                        id="contactname"
                        name="contactname"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter contact person "
                        onChange={this.onInputChange}
                        required
                        value={this.state.contactname}
                      />
                    </div> */}
                    <div className="col-md-6 form-group">
                      <label>Contact Email</label>
                      <input
                        id="contactemail"
                        name="contactemail"
                        type="email"
                        className="form-control input-lg input"
                        placeholder="Enter contact person "
                        onChange={this.onInputChange}
                        required
                        disabled={true}
                        value={this.state.contactemail}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <label>Contact Phone</label>
                      <input
                        id="contactphone"
                        name="contactphone"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter contact person "
                        onChange={this.onInputChange}
                        required
                        disabled={true}
                        value={this.state.contactphone}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <label>Username (leave blank for autogenerate)</label>
                      <input
                        id="username"
                        name="username"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter user name "
                        onChange={this.onInputChange}
                        disabled={true}
                        value={this.state.username}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <label>Password (leave blank for autogenerate)</label>
                      <input
                        id="password"
                        name="password"
                        type="text"
                       // disabled={true}
                        className="form-control input-lg input"
                        placeholder="Enter Password"
                        onChange={this.onInputChange}
                        value={this.state.password}
                      />
                    </div>
                  </div>

                  <div className="form-action bg-widget position-relative form-group">
                    <button className="btn btn-sm btn-success mr-xs ">
                      Save
                    </button>
                    <button className="btn btn-sm btn-danger">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h4>&nbsp;</h4>
                <div className="widget-padding-md border rounded">
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <FilePond
                        name={"file"}
                        accept="image/png, image/jpeg, image/gif"
                        ref={ref => (this.pond = ref)}
                        allowMultiple={false}
                        maxFiles={3}
                        server={
                          server //server={keys.ServicePath + "/api/uploadimage"}
                        }
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                          this.setState({
                            files: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                        fileValidateTypeLabelExpectedTypesMap={{
                          "image/jpeg": ".jpg"
                        }}
                        labelFileTypeNotAllowed={"Upload JPEG files allowed ."}
                        allowFileSizeValidation={true}
                        maxTotalFileSize={10485760}
                        onremovefile={file => {
                          this.setState({ image1: "" });
                        }}
                        //allowImagePreview={false}
                      >
                        {this.state.files.map(file => (
                          <File key={file} src={file} origin="local" />
                        ))}
                      </FilePond>
                      {/* {this.state.image1 !== "" && (
                        <img
                          alt="image1"
                          src={this.state.image1}
                          id="image0"
                          height={175}
                          width={175}
                        />
                      )} */}
                    </div>
                    <div className="col-md-4 form-group">
                      <FilePond
                        name={"file"}
                        accept="image/png, image/jpeg, image/gif"
                        ref={ref => (this.pond1 = ref)}
                        allowMultiple={false}
                        maxFiles={3}
                        server={
                          server //server={keys.ServicePath + "/api/uploadimage"}
                        }
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                          this.setState({
                            files1: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                        fileValidateTypeLabelExpectedTypesMap={{
                          "image/jpeg": ".jpg"
                        }}
                        labelFileTypeNotAllowed={"Upload JPEG files allowed ."}
                        allowFileSizeValidation={true}
                        maxTotalFileSize={10485760}
                        onremovefile={file => {
                          this.setState({ image2: "" });
                        }}
                      >
                        {this.state.files1.map(file => (
                          <File key={file} src={file} origin="local" />
                        ))}
                      </FilePond>
                      {/* {this.state.image2 !== "" && (
                        <img
                          src={this.state.image2}
                          alt="image2"
                          id="image1"
                          height={175}
                          width={175}
                        />
                      )} */}
                    </div>
                    <div className="col-md-4 form-group">
                      <FilePond
                        name={"file"}
                        accept="image/png, image/jpeg, image/gif"
                        ref={ref => (this.pond2 = ref)}
                        allowMultiple={false}
                        maxFiles={3}
                        server={
                          server //server={keys.ServicePath + "/api/uploadimage"}
                        }
                        oninit={() => this.handleInit()}
                        onupdatefiles={fileItems => {
                          this.setState({
                            files2: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                        fileValidateTypeLabelExpectedTypesMap={{
                          "image/jpeg": ".jpg"
                        }}
                        labelFileTypeNotAllowed={"Upload JPEG files allowed ."}
                        allowFileSizeValidation={true}
                        maxTotalFileSize={10485760}
                        onremovefile={file => {
                          this.setState({ image3: "" });
                          
                        }}
                      >
                        {this.state.files2.map(file => (
                          <File key={file} src={file} origin="local" />
                        ))}
                      </FilePond>
                      {/* {this.state.image3 !== "" && (
                        <img
                          src={this.state.image3}
                          alt="image1"
                          id="image2"
                          height={175}
                          width={175}
                        />
                      )} */}
                    </div>
                  </div>{" "}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, lstAgents, ui, lstcitystate }) {
  return { auth, lstAgents, ui, lstcitystate };
}
export default connect(
  mapStateToProps,
  {
    getallagents,
    insertagent,
    getcitystate,
    deleteagent,
    updateagent,
    terminateagent
  }
)(UserProfile);
