import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import DashboardMaster from "../Dashboard/dashboard";
import User from "../usercreation/user";
import Agent from "../agent/agent";
import AgentEnquiry from "../agent/agentenquiry";
import UsedKeys from "../agent/usedkeys";
import LicKeyGenerator from "../agent/lickeygenerator";
import PurchaseKey from "../agent/purchaseKey";
import MyKeys from "../agent/agentkeys";
import QuestionBank from "../questionbank/questionbank";
import Product from "../product/product";
import UserProfile from "../agent/userprofile";
import Configuration from "../appconfiguration/configuration";
import PaymentReceipt from "../agent/paymentReceipt";
import Receipt from "../agent/receiptgeneration";
import { logout } from "../../actions/auth";
//import AgentEnquiry from "../agent/agentenquiry"
import moment from "moment";
//import user from "../usercreation/user";

class LeftSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      menuOpen: false,
      showUserLinks: false,
      menuItemsAdmin: [
        {
          to: "/superadmin/dashboard",
          text: "Dashboard",
          iconclassName: "menu-icon fa fa-dashboard"
        }
      ]
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  renderMenuItems = () => {
    let menus = [];
    if (this.props.auth.userType === 2 || this.props.auth.userType === 1) {
      menus = this.state.menuItemsAdmin;
    } else if (
      this.props.auth.userType === null ||
      this.props.auth.userType === undefined
    ) {
      menus = [];
    } else {
      menus = this.state.menuItemsWarehouse;
    }
    return menus.map(menu => {
      return (
        <li key={menu.text} className="nav-item">
          <Link to={menu.to} className="nav-link">
            <i classNameName={menu.iconclassName} />
            <span classNameName="menu-title">{menu.text}</span>
          </Link>
        </li>
      );
    });
  };

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  render() {
    const userdetails = JSON.parse(localStorage.getItem("userdetails"));

    return (
      <div>
        <div className="logo">
          <h4>
            {userdetails.usertype === "admin"
              ? "RTO Admin"
              : userdetails.agentname}{" "}
          </h4>
        </div>
        <nav
          id="sidebar"
          className={
            this.state.menuOpen
              ? "sidebar nav-collapse collapse open in"
              : "sidebar nav-collapse collapse"
          }
        >
          {userdetails.usertype === "admin" && (
            <ul id="side-nav" className="side-nav">
              <li className="active">
                <Link to="/dashboard">
                  <i className="fa fa-home" />
                  <span className="name">{"Dashboard"}</span>
                </Link>
              </li>

              <li className="">
                <Link to="/user">
                  <i className="fa fa-user" />
                  <span className="name">{"User"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/agent">
                  <i className="fa fa-users" />
                  <span className="name">{"Agent"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/products">
                  <i className="fa fa-product-hunt" />
                  <span className="name">{"Products"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/keygenerator">
                  <i className="fa fa-key" />
                  <span className="name">{"Licence Key Generator"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/receipt">
                  <i className="fa fa-file-text" />
                  <span className="name">{"Receipt"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/enquiry">
                  <i className="fa fa-question" />
                  <span className="name">{"Enquiries"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/usedkeys">
                  <i className="fa fa-user-times" />
                  <span className="name">{"Used Keys"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/settings">
                  <i className="fa fa-cogs" />
                  <span className="name">{"Configuration"}</span>
                </Link>
              </li>
            
            </ul>
          )}
          {userdetails.usertype !== "admin" && (
            <ul id="side-nav" className="side-nav">
              <li className="active">
                <Link to="/userprofile">
                  <i className="fa fa-user" />
                  <span className="name">{"Profile"}</span>
                </Link>
              </li>
              {/* <li className="active">
                <Link to="/dashboard">
                  <i className="fa fa-home" />
                  <span className="name">{"User Profile"}</span>
                </Link>
              </li> */}
              <li className="active">
                <Link to="/mykeys">
                  <i className="fa fa-key" />
                  <span className="name">{"My Keys"}</span>
                </Link>
              </li>
              <li className="active">
                <Link to="/dashboard">
                  <i className="fa fa-shopping-cart " />
                  <span className="name">{"Keys Distributed"}</span>
                </Link>
              </li>
              <li className="">
                <Link to="/purchasekey">
                  <i className="fa fa-key" />
                  <span className="name">{"Purchase Key"}</span>
                </Link>
              </li>
            </ul>
          )}

          {/* <h5 className="sidebar-nav-title">
            Labels{" "}
            <a className="action-link" href="#">
              <i className="glyphicon glyphicon-plus" />
            </a>
          </h5> */}
        </nav>
        <div className="wrap">
          <header className="page-header">
          
            <div className="navbar">
              <ul className="nav navbar-nav navbar-right pull-right">
              
                <li >
                <span className="keys navbar-form pull-right">{"TOTAL KEYS : "}<span className="blue_font_bold"> {userdetails.totalkeysissued}</span></span>
                </li>
                <li >
                <span className="keys navbar-form pull-right">{"USED KEYS : "}<span className="green_font_bold"> { userdetails.totalkeysused}</span></span>
                </li>
                <li className="divider" />

                <li className="visible-xs">
                  <button
                    className="btn-navbar"
                    data-toggle="collapse"
                    data-target=".sidebar"
                    onClick={this.toggleMenu}
                    title=""
                  >
                    <i className="fa fa-bars" />
                  </button>
                </li>
                <li className="hidden-xs">
                  <a href="/" onClick={this.props.logout}>
                    <i className="glyphicon glyphicon-off" />
                  </a>
                </li>
              </ul>
              
              {/* <form
                id="search-form"
                className="navbar-form pull-right"
                role="search"
              >
                <input
                  type="search"
                  className="form-control search-query"
                  placeholder="Search..."
                />
              </form> */}
            </div>
          </header>
          <div
            className={
              this.state.menuOpen
                ? "content container margintop"
                : "content container"
            }
          >
            {userdetails.usertype === "admin" && (
              <div>
                <Route exact path="/dashboard" component={DashboardMaster} />
                <Route exact path="/user" component={User} />
                <Route exact path="/agent" component={Agent} />
                <Route exact path="/keygenerator" component={LicKeyGenerator} />
                <Route exact path="/questionbank" component={QuestionBank} />
                <Route exact path="/products" component={Product} />
                <Route exact path="/enquiry" component={AgentEnquiry} />
                <Route exact path="/usedkeys" component={UsedKeys} />
                <Route exact path="/settings" component={Configuration} />
                <Route exact path="/paymentreceipt" component={PaymentReceipt} />
                <Route exact path="/receipt" component={Receipt} />
                
              </div>
            )}
            {userdetails.usertype !== "admin" && (
              <div>
                <Route exact path="/dashboard" component={DashboardMaster} />
                <Route exact path="/mykeys" component={MyKeys} />
                <Route exact path="/userprofile" component={UserProfile} />
                <Route exact path="/purchasekey" component={PurchaseKey} />
                <Route exact path="/paymentreceipt" component={PaymentReceipt} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(
  mapStateToProps,
  { logout }
)(LeftSideBar);
