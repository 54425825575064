import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/auth";
import { Redirect } from "react-router-dom";
import logo from "../../resources/img/favicon.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { showforgot: false, messagetype: "" };
  }

  showForgotPassword = () => {
    this.setState({ showforgot: true });
    
  };

  showLogin = () => {
    this.setState({ showforgot: false });
  };

  userLogin = async event => {
    event.preventDefault();

    await this.props.fetchUser({
      username: this.state.username,
      password: this.state.password
    });

    this.setState({ messagetype: this.props.auth.messagetype });
  };

  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // componentDidUpdate() {
  //   if (this.state.messagetype !== "") {
  //     alert(this.state.messagetype);
  //     notification("error", this.props.auth.message, "Authentication");
  //     this.setState({ messagetype: "" });
  //   }
  // }
  render() {
    //const { showforgot } = this.state;
    //return <Redirect to="/dashboard" />;
    if (
      this.props.auth.redirectToApplication === true &&
      this.props.auth.logOut === false &&
      this.props.auth.userId > 0
    ) {
      if (this.props.auth.userType === "admin") return <Redirect to="/user" />;
      else return <Redirect to="/mykeys" />;
    }
    return (
      <div className="single-widget-container">
        <section className="widget login-widget">
          <header className="text-align-center blacktext">
            <img src={logo} alt="MDTS" />
            <h4>Login to MDTS</h4>
          </header>
          <div className="body">
            <form className="no-margin" onSubmit={this.userLogin}>
              <fieldset>
                <div className="form-group">
                  <label>Username</label>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="fa fa-user" />
                    </span>
                    <input
                      id="username"
                      name="username"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Your Email"
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label for="password">Password</label>

                  <div className="input-group input-group-lg">
                    <span className="input-group-addon">
                      <i className="fa fa-lock" />
                    </span>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="form-control input-lg input"
                      placeholder="Your Password"
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
              </fieldset>
              <div className="form-actions">
                <button
                  type="submit"
                  className="btn btn-block btn-lg btn-danger"
                >
                  <span className="small-circle">
                    <i className="fa fa-caret-right" />
                  </span>
                  <small>Sign In</small>
                </button>
                <div className="forgot" />
              </div>
            </form>
          </div>
          {/* <footer>
            <div className="facebook-login">
              <a href="index.html">
                <span>
                  <i className="fa fa-facebook-square fa-lg" /> LogIn with Facebook
                </span>
              </a>
            </div>
          </footer> */}
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //console.log(auth);
  return { auth: state.auth };
}

export default connect(
  mapStateToProps,
  actions
)(Login);
