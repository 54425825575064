import {
  GET_ALL_USERS,
  GET_CITY_STATE,
  GET_STATE,
  GET_ALL_AGENTS,
  UPDATE_DATA,
  UPDATE_DATA_PURCHASE_FORM,
  GENERATE_LICENCE_KEYS,
  ADD_PAYMENT_DATA,
  GET_ALL_LICENCEKEYS,
  GET_ALL_PRODUCTS,
  GET_ALL_AGENTS_ENQUIRY,
  UPDATE_DATA_ENQUIRY,
  DELETE_KEYS,
  GET_DASHBOARD,
  GET_USED_KEYS,
  GET_ALL_PURCHASE_FORM,
  GET_RECEIPT_NO,
  GET_PENDING_INVOICES,
} from "../actions/types";

const initialState = [];

export const lstUsers = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return action.payload;
    default:
      return state;
  }
};

export const lstcitystate = function (state = initialState, action) {
  switch (action.type) {
    case GET_CITY_STATE:
      return action.payload;
    default:
      return state;
  }
};
export const lststate = function (state = initialState, action) {
  switch (action.type) {
    case GET_STATE:
      return action.payload;
    default:
      return state;
  }
};

export const lstAgents = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_AGENTS:
      return action.payload;
    default:
      return state;
  }
};

export const insertAgent = function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const insertPurchaseForm = function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA_PURCHASE_FORM:
      return action.payload;
    default:
      return state;
  }
};

export const updateAgent = function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const generatelicencekeys = function (state = initialState, action) {
  switch (action.type) {
    case GENERATE_LICENCE_KEYS:
      return action.payload;
    default:
      return state;
  }
};

export const addPaymentData = function (state = initialState, action) {
  switch (action.type) {
    case ADD_PAYMENT_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const lstlickeys = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LICENCEKEYS:
      return action.payload;
    default:
      return state;
  }
};

export const listPurchaseForm = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PURCHASE_FORM:
      return action.payload;
    default:
      return state;
  }
};

export const lstProduct = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

export const lstAgentsEnquiry = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_AGENTS_ENQUIRY:
      return action.payload;
    default:
      return state;
  }
};

export const insertAgentEnquiry = function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA_ENQUIRY:
      return null;
    default:
      return state;
  }
};

export const updateAgentEnquiry = function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA_ENQUIRY:
      return action.payload;
    default:
      return state;
  }
};

export const deleteKeys = function (state = initialState, action) {
  switch (action.type) {
    case DELETE_KEYS:
      return action.payload;
    default:
      return state;
  }
};

export const dashboardDetails = function (state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
};

export const lstusedkeys = function (state = initialState, action) {
  switch (action.type) {
    case GET_USED_KEYS:
      return action.payload;
    default:
      return state;
  }
};
export const receiptno = function (state = initialState, action) {
  switch (action.type) {
    case GET_RECEIPT_NO:
      return action.payload;
    default:
      return state;
  }
};

export const pendinginvoicelist = function (state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_INVOICES:
      return action.payload;
    default:
      return state;
  }
};
