import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import GridActions from "../common/ag-grid/GridActions";
import { getReceiptNo, getPendingInvoices,insertReceipt } from "../../actions/user";
class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colDefs: [
        { field: "agentname", headerName: "Agent" },
        { field: "contactphone", headerName: "Phone" },
        { field: "receiptno", headerName: "Receipt No" },
        { field: "amount", headerName: "Total Amount" },
        { field: "pendingamount", headerName: "Pending Amount" },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params",
        },
      ],
      rowData: [],
      context: { componentParent: this },
      showGridDelete: false,
      showGridEdit: false,
      showGridCustom1: true,
      showGridCustom1Lbl: "Select",
      selectedcustomer: {},
      paymentmode: [
        { value: "CASH", label: "CASH" },
        { value: "CHEQUE", label: "CHEQUE" },
        { value: "DEPOSIT", label: "DEPOSIT" },
      ],
    };
  }

  componentDidMount() {
    this.props.getReceiptNo();
    this.props.getPendingInvoices();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  agGridCustom1 = (row) => {
    //console.log(moment(row.createddatetime).format("DD/MM/YYYY"),dateFormat(Date(row.createddatetime),"dd/mm/yyyy"))
    this.setState({
      customer: row.agentname,
      contactno: row.contactphone,
      address: row.address,
      state: row.state,
      city: row.city,
      amount: row.amount,
      pendingamount: row.pendingamount,
      invoiceno: row.receiptno,
      id: row.id,
      receiptamount:row.pendingamount,
      userid:row.userid
    });
  };

  onInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onStartDateChange = (chdate) => this.setState({ chdate });

  onPaymentmodeChange = async (selectedOption) => {
    await this.setState({
      selectedPaymentMode: selectedOption.value,
      selectedOption,
    });
  };

  handleSubmit = () => {
    console.log(this.state);
    let obj={
      customer:this.state.customer,
      contactno:this.state.contactno,
      address: this.state.address,
      state: this.state.state,
      city: this.state.city,
      amount: this.state.amount,
      pendingamount: this.state.pendingamount,
      againstreceiptno: this.state.invoiceno,
      againstinvoice: this.state.id,
      receiptamount:this.state.receiptamount,
      userid:this.state.userid,
      receiptno:this.props.receiptno,
      paymentmode:this.state.selectedPaymentMode
    }
    this.props.insertReceipt(obj);

  };
  render() {
    return (
      <div className="widget">
        <div className="card-header" />
        <h3 className="card-title">
          Receipt Generation
          <p className="card-category">{"Create Receipt"}</p>
        </h3>

        <div className="widget-body">
          <div className="row">
            <div className="col-md-6">
              <h4>Create Receipt</h4>
              <div className="widget-padding-md border rounded">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>
                      <strong>Receipt No: </strong> {this.props.receiptno}
                    </label>
                  </div>

                  <div className="col-md-6 form-group">
                    <label>
                      <strong>Customer: </strong>
                      {this.state.customer}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label>
                      <strong>Phone: </strong>
                      {this.state.contactno}
                    </label>
                  </div>
                  <div className="col-md-4 form-group">
                    <label>
                      <strong>City: </strong>
                      {this.state.city}
                    </label>
                  </div>
                  <div className="col-md-4 form-group">
                    <label>
                      <strong>State: </strong>
                      {this.state.state}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>
                      <strong>Total Amount: </strong>
                      {this.state.amount}
                    </label>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <strong>Pending Amount: </strong>
                      {this.state.pendingamount}
                    </label>
                  </div>
                </div>
                <hr className="hr"></hr>
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label>
                      <strong>Receipt Amount:</strong>
                    </label>
                    <input
                      id="receiptamount"
                      name="receiptamount"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Receipt Amount"
                      required
                      defaultValue={this.state.pendingamount}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>
                      <strong>CH/CC/DD No:</strong>
                    </label>
                    <input
                      id="chccddno"
                      name="chccddno"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Cheque/DD No"
                      required
                      defaultValue={this.state.chccddno}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>
                      <strong>CH/CC/DD Date:</strong>
                    </label>
                    <DatePicker
                      className="form-control input-lg input"
                      onChange={this.onStartDateChange}
                      value={this.state.chdate}
                      format="dd-MM-y"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <strong>Bank Name:</strong>
                    </label>
                    <input
                      id="bankname"
                      name="bankname"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Bank Name"
                      required
                      defaultValue={this.state.bankname}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <strong>Payment Mode :</strong>
                    </label>

                    <Select
                      className="input-select input-select"
                      classNamePrefix="select"
                      name="charset"
                      onChange={this.onPaymentmodeChange}
                      options={this.state.paymentmode}
                      value={this.state.selectedOption}
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label>
                      <strong>Remarks:</strong>
                    </label>
                    <input
                      id="bankname"
                      name="bankname"
                      type="text"
                      className="form-control input-lg input"
                      placeholder="Bank Name"
                      required
                      defaultValue={this.state.bankname}
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>

                <div className="form-action bg-widget position-relative form-group">
                  <button
                    className="btn btn-sm btn-success mr-xs "
                    onClick={this.handleSubmit}
                  >
                    Save
                  </button>
                  <button className="btn btn-sm btn-danger">Cancel</button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h4>Pending Invoice List</h4>
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    rowData={this.props.pendinginvoicelist}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, pendinginvoicelist, ui, receiptno }) {
  return { auth, pendinginvoicelist, ui, receiptno };
}

export default connect(mapStateToProps, {
  getReceiptNo,
  getPendingInvoices,
  insertReceipt
})(Receipt);
