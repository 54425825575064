import { FETCH_USER, LOGOUT, CHECK_TOKEN } from "../actions/types";

const initialState = {
  isLoggedIn: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, ...action.payload };
    case CHECK_TOKEN:
      return { ...state, ...action.payload };
    case LOGOUT:
      return action.payload || false;
    default:
      return { ...state, ...action.payload };
  }
}
