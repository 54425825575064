import axios from "axios";

export default token => {
  // token
  //   ? (axios.defaults.headers.common["token"] = token)
  //   : delete axios.defaults.headers.common["token"];

  if (token) {
    axios.defaults.headers.common["token"] = token;
    //console.log(jwt_decode(token));
  } else {
    delete axios.defaults.headers.common["token"];
  }
};
