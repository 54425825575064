import React, { Component } from "react";
import { connect } from "react-redux";

import {
  generatelicencekeys,
  getalllicencekeysById,
  getallagents,
  getallproducts,
  getAllPurchaseForm
} from "../../actions/user";

//import Select from "react-select";
import Modal from "react-responsive-modal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
//import moment from "moment";

class PaymentReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {},
      showinvoice: false,
      modelOpen1: false,
      loader: false,
      Invoicetoprint: [1, 2]
    };
  }

  componentDidMount() {
    var search = this.props.location.search.substring(1);
    var objresponse = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    this.setState({ response: objresponse }, () => {
      this.setState({ showinvoice: true });
      this.setState({ refresh: !this.state.refres });
    });
    if (objresponse.status === "success") {
      this.setState({ showinvoice: true });
    }
  }
  onCloseModal = () => {
    this.setState({ modelOpen1: false });
  };
  printInvoice = async row => {
    // console.log("print row", row);
    await this.setState({ loader: true });
    await this.props.getAllPurchaseForm({
      agentId: this.state.response.udf2,
      batchCode: this.state.response.udf3
    });
    // console.log('Res == ',this.props.listPurchaseForm)

    if (this.props.listPurchaseForm.length > 0) {
      await this.setState(
        {
          agentname: this.props.listPurchaseForm[0].agentname,
          address: this.props.listPurchaseForm[0].address,
          cityname: this.props.listPurchaseForm[0].cityname,
          statename: this.props.listPurchaseForm[0].statename,
          stateid: this.props.listPurchaseForm[0].stateid,
          contactemail: this.props.listPurchaseForm[0].contactemail,
          contactphone: this.props.listPurchaseForm[0].contactphone,
          date: this.props.listPurchaseForm[0].createddatetime,
          quantity: this.props.listPurchaseForm[0].quantity,
          rate: this.props.listPurchaseForm[0].rate,
          total: this.props.listPurchaseForm[0].total,
          product: this.props.listPurchaseForm[0].product,
          gstno: this.props.listPurchaseForm[0].gstno,
          batchCode: row.batchcode,
          resultEmpty: false
        },
        () => {
          this.setState({ modelOpen1: true, loader: false });
        }
      );
    } else {
      this.setState({ modelOpen1: true, loader: false, resultEmpty: true });
    }
  };

  printDocument = row => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("Invoice.pdf");
    });
  };
  render() {
    return (
      <section className="widget">
        <h3 className="card-title">Purchase Key</h3>
        <div className="widget-body">
          <div className="row">
            <div className="col-md-6">
              <div className="widget-padding-md border rounded">
                <div className="row">
                  <table>
                    <tr>
                      <td>
                        <b>{"Transaction No."}</b>
                      </td>
                      <td>{this.state.response.mihpayid}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{"Message"}</b>
                      </td>
                      <td>{this.state.response.status}</td>
                    </tr>
                    {this.state.showinvoice}
                    <tr>
                      <td colSpan="2">
                        <button
                          onClick={this.printInvoice}
                          className="btn btn-lg btn-success"
                        >View Invoice</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={this.state.modelOpen1} onClose={this.onCloseModal} center>
          <div className="row">
            <div className="col-lg-12">
              <button
                className="btn btn-sm btn-success mr-xs "
                onClick={this.printDocument}
              >
                <i className="fa fa-print" /> Print
              </button>
            </div>

            <div className="col-lg-12">
              <div className="row invoice-body" id="divToPrint">
                <section>
                  {this.state.Invoicetoprint.map((item, index) => {
                    return (
                      <div>
                        <header className="clearfix">
                          <div id="logo">
                            <img
                              alt="logo"
                              src="http://localhost:3000/static/media/favicon.7645d66a.png"
                            />
                          </div>
                          <div id="company">
                            <h2 className="name">BHAVESH AJMERI</h2>
                            <div>G-3, Shiv Chambers, Sardar Bhavan Lane,</div>
                            <div>
                              Raopura, Vadodara - 390001. Gujarat. (India)
                            </div>
                            <div>
                              9426376678 |&nbsp;
                              <a href="mailto:bhavesh456ajmeri@gmail.com">
                                bhavesh456ajmeri@gmail.com
                              </a>
                            </div>
                          </div>
                        </header>

                        {this.state.resultEmpty ? (
                          <main className="text-center">No data found</main>
                        ) : (
                          <main>
                            <div id="details" className="clearfix">
                              <div id="client">
                                <h2 className="name">
                                  M/s. {this.state.agentname}
                                </h2>
                                <div className="address">
                                  {this.state.address}, {this.state.cityname},{" "}
                                  {this.state.statename}
                                </div>
                                <div className="email">
                                  <a href={this.state.contactemail}>
                                    {this.state.contactemail}
                                  </a>
                                </div>
                              </div>
                              <div id="invoice">
                                <h1>Invoice No # {this.state.batchCode}</h1>
                                <div className="date">
                                  GST No: {this.state.gstno}
                                </div>
                                <div className="date">
                                  Date of Invoice: {this.state.date}
                                </div>
                              </div>
                            </div>
                            <table
                              className="borderTable"
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <thead>
                                <tr>
                                  <th className="no">#</th>
                                  <th className="desc">Particulars</th>
                                  <th className="unit">Qty.</th>
                                  <th className="qty">Rate</th>
                                  <th className="total">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="no">01</td>
                                  <td className="desc">
                                    <h3>MDTS Book</h3>
                                  </td>
                                  <td className="unit">
                                    {this.state.quantity}
                                  </td>
                                  <td className="qty">
                                    &#x20b9;{this.state.rate}
                                  </td>
                                  <td className="total">
                                    &#x20b9;{this.state.total}{" "}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot border="0">
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan="2">SUBTOTAL</td>
                                  <td>
                                    &#x20b9;
                                    {this.state.total -
                                      (5 * this.state.total) / 100}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan="2">TAX 5%</td>
                                  <td>
                                    &#x20b9;{(5 * this.state.total) / 100}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan="2">GRAND TOTAL</td>
                                  <td>&#x20b9;{this.state.total}</td>
                                </tr>
                              </tfoot>
                            </table>

                            {this.state.totalInWords &&
                            this.state.totalInWords !== "" ? (
                              <div id="invoice">
                                Amount Chargeable (in words) :{" "}
                                {this.state.totalInWords}
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.stateid && this.state.stateid === 12 ? (
                              <table
                                className="taxTable taxTable1"
                                border="1"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <thead>
                                  <tr>
                                    <th className="no">Taxable Value</th>
                                    <th colSpan="2" className="desc">
                                      CGST
                                    </th>
                                    <th colSpan="2" className="unit">
                                      SGST
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="no"></td>
                                    <td className="desc">Rate (%)</td>
                                    <td className="desc">Amt.</td>
                                    <td className="unit">Rate (%)</td>
                                    <td className="unit">Amt.</td>
                                  </tr>

                                  <tr>
                                    <td className="no">
                                      &#x20b9;
                                      {this.state.total -
                                        (5 * this.state.total) / 100}
                                    </td>
                                    <td className="desc">2.5</td>
                                    <td className="desc">
                                      &#x20b9;{(2.5 * this.state.total) / 100}
                                    </td>
                                    <td className="unit">2.5</td>
                                    <td className="unit">
                                      &#x20b9;{(2.5 * this.state.total) / 100}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            ) : (
                              <table
                                className="taxTable"
                                border="1"
                                cellSpacing="0"
                                cellPadding="0"
                              >
                                <thead>
                                  <tr>
                                    <th className="no">Taxable Value</th>
                                    <th colSpan="2" className="qty">
                                      IGST
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="no"></td>
                                    <td className="qty">Rate (%)</td>
                                    <td className="qty">Amt.</td>
                                  </tr>
                                  <tr>
                                    <td className="no">
                                      {this.state.total -
                                        (5 * this.state.total) / 100}
                                    </td>
                                    <td className="qty">5</td>
                                    <td className="qty">
                                      &#x20b9;{(5 * this.state.total) / 100}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}

                            <table className="taxTable" width="100%">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Terms & Condition : </b>Subject to{" "}
                                    {this.state.cityname} Jurisdiction
                                  </td>
                                  <td>
                                    <b>Payment Mode : </b>Cash
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <div id="thanks">Thank you!</div> */}
                            <div id="notices">
                              <div className="notice">
                                NOTICE : IGST will be Count only Out of Gujarat
                                Sale. Otherwise Filled remain Blank.
                              </div>
                            </div>
                            {index !== this.state.Invoicetoprint.length - 1 && (
                              <div>
                                --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                              </div>
                            )}
                          </main>
                        )}
                      </div>
                    );
                  })}
                </section>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}
function mapStateToProps({
  auth,
  lstlickeys,
  ui,
  lstAgents,
  lstProduct,
  listPurchaseForm
}) {
  return { auth, ui, lstlickeys, lstAgents, lstProduct, listPurchaseForm };
}

export default connect(mapStateToProps, {
  generatelicencekeys,
  getalllicencekeysById,
  getallagents,
  getallproducts,
  getAllPurchaseForm
})(PaymentReceipt);

//export default PaymentReceipt;
