import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { getallusers } from "../../actions/auth";
import { insertuser, deleteuser } from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";
import Select from "react-select";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslot: "day",
      colDefs: [
        { field: "username", headerName: "User Name" },
        { field: "password", headerName: "Password" },
        { field: "usertype", headerName: "Admin Type" },
        {
          headerName: "Actions",
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params"
        }
      ],
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: false
    };
  }

  componentDidMount = async () => {
    await this.props.getallusers();

    this.setState({ rowData: this.props.lstUsers });
  };

  onChange = async selectedOption =>
    await this.setState({ usertype: selectedOption.value });

  onInputChange = async e =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleSubmit = e => {
    e.preventDefault();

    const userdata = {
      username: this.state.username,
      password: this.state.password,
      usertype: this.state.usertype
    };
    // console.log(PurchaseDatas);
    
    this.props.insertuser(userdata);
  };

  agGridDelete = row => {
    this.props.deleteuser({ id: row.id });
  };
  render() {
    return (
      <div className="widget">
        <div className="card-header" />
        <h3 className="card-title">
          User Management
          <p className="card-category">{"User Creation"}</p>
        </h3>

        <div className="widget-body">
          <div className="row">
            <div className="col-md-6">
              <h4>Create User</h4>
              <form onSubmit={this.handleSubmit}>
                <div className="widget-padding-md border rounded">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Username</label>

                      <input
                        id="username"
                        name="username"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter username"
                        onChange={this.onInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label>Password</label>

                      <input
                        id="password"
                        name="password"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter password"
                        onChange={this.onInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label>User Type</label>

                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="usertype"
                        onChange={this.onChange}
                        options={[
                          { value: "admin", label: "admin" },
                          { value: "manager", label: "manager" }
                        ]}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-action bg-widget-transparent position-relative form-group">
                    <button className="btn btn-sm btn-success mr-xs ">
                      Save
                    </button>
                    <button className="btn btn-sm btn-danger">Cancel</button>
                  </div>
                </div>
              </form>{" "}
            </div>
            <div className="col-md-6">
              <h4>User List</h4>
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    rowData={this.props.lstUsers}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, lstUsers, ui }) {
  return { auth, lstUsers, ui };
}

// const mapStateToProps() {
//   return {
//     user,
//     lstUsers
//   };
// };
export default connect(
  mapStateToProps,
  { getallusers, insertuser, deleteuser }
)(User);
