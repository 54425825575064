import axios from "axios";
import {
  GET_ALL_USERS,
  GET_CITY_STATE,
  GET_ALL_AGENTS,
  UPDATE_DATA,
  UPDATE_DATA_PURCHASE_FORM,
  GENERATE_LICENCE_KEYS,
  ADD_PAYMENT_DATA,
  GET_ALL_LICENCEKEYS,
  UPDATE_AGENT,
  GET_ALL_PRODUCTS,
  UPDATE_AGENT_ENQUIRY,
  GET_ALL_AGENTS_ENQUIRY,
  UPDATE_DATA_ENQUIRY,
  DELETE_KEYS,
  GET_STATE,
  GET_DASHBOARD,
  GET_USED_KEYS,
  GET_ALL_PURCHASE_FORM,
  GET_RECEIPT_NO,
  GET_PENDING_INVOICES,
} from "./types";
const keys = require("../config/keys");

export const insertuser = (data) => async (dispatch) => {

  await axios({
    method: "post",
    url: keys.ServicePath + "/api/insertuser",
    data: data,
  })
    .then((res) => {
      

      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_USERS, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const deleteuser = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deleteuser",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_USERS, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getcitystate = (data) => async (dispatch) => {
  await axios({
    method: "get",
    url: keys.ServicePath + "/api/getcitystate",
    data: data,
  })
    .then((res) => {
      if (res.data) {
        try {
          var citystatedata = [];
          res.data.data[0].forEach((item) => {
            item["cities"] = res.data.data[1].filter(
              (cdata) => cdata.state_id === item.id
            );
            citystatedata.push(item);
          });

          var data = {
            citystate: citystatedata,
            city: res.data.data[1],
            state: res.data.data[0],
          };
          dispatch({ type: GET_CITY_STATE, payload: data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getstate = (data) => async (dispatch) => {
  await axios({
    method: "get",
    url: keys.ServicePath + "/api/getstate",
    data: data,
  })
    .then((res) => {
      if (res.data) {
        try {
          dispatch({ type: GET_STATE, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const insertagent = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/insertagent",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: UPDATE_DATA, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const insertPurchaseForm = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/insertPurchaseForm",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: UPDATE_DATA_PURCHASE_FORM, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const deletePurchaseForm = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deletePurchaseForm",
    data: data,
  })
    .then((res) => {
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_PURCHASE_FORM, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

// function getBase64Image(img) {
//   var canvas = document.createElement("canvas");
//   canvas.width = img.width;
//   canvas.height = img.height;
//   var ctx = canvas.getContext("2d");
//   ctx.drawImage(img, 0, 0);
//   var dataURL = canvas.toDataURL("image/png");
//   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
// }

export const getallagents = (Id) => async (dispatch) => {
  var apiurl = keys.ServicePath + "/api/getagentlist";
  if (Id !== undefined)
    apiurl = keys.ServicePath + "/api/getagentlistbyId?Id=" + Id;

  await axios({ method: "get", url: apiurl })
    .then((res) => {
      try {
        dispatch({ type: GET_ALL_AGENTS, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const deleteagent = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deleteagent",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_AGENTS, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
export const terminateagent = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/terminateagent",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_AGENTS, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const updateagent = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/updateagent",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: UPDATE_AGENT, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const generatelicencekeys = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/generatelicencekeys",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: GENERATE_LICENCE_KEYS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const addPaymentData = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/addPaymentData",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: ADD_PAYMENT_DATA,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getalllicencekeys = (Id) => async (dispatch) => {
  await axios({
    method: "get",
    url: keys.ServicePath + "/api/getalllicencekeys",
  })
    .then((res) => {
      try {
        var data = [];
        res.data.data[0].map((item) => {
          item["licencedetails"] = res.data.data[1].filter(
            (f) => f.batchcode === item.batchcode && f.agentid === item.agentid
          );
          data.push(item);
          return "";
        });

        dispatch({ type: GET_ALL_LICENCEKEYS, payload: data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getalllicencekeysByDate = (fromDate, toDate) => async (
  dispatch
) => {
  await axios({
    method: "get",
    url:
      keys.ServicePath +
      "/api/getalllicencekeysByDate?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate,
  })
    .then((res) => {
      try {
        var data = [];
        res.data.data[0].map((item) => {
          item["licencedetails"] = res.data.data[1].filter(
            (f) => f.batchcode === item.batchcode && f.agentid === item.agentid
          );
          data.push(item);
          return "";
        });

        dispatch({ type: GET_ALL_LICENCEKEYS, payload: data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getalllicencekeysById = (Id) => async (dispatch) => {
  await axios({
    method: "get",
    url: keys.ServicePath + "/api/getalllicencekeysById?Id=" + Id,
  })
    .then((res) => {
      try {
        var data = [];
        res.data.data[0].map((item) => {
          item["licencedetails"] = res.data.data[1].filter(
            (f) => f.batchcode === item.batchcode
          );
          data.push(item);
          return "";
        });

        dispatch({ type: GET_ALL_LICENCEKEYS, payload: data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getAllPurchaseForm = (data) => async (dispatch) => {
  await axios({
    method: "get",
    url:
      keys.ServicePath +
      "/api/getAllPurchaseForm?agentId=" +
      data.agentId +
      "&batchCode=" +
      data.batchCode,
  })
    .then((res) => {
      try {
        dispatch({ type: GET_ALL_PURCHASE_FORM, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const insertproduct = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/insertproduct",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: GET_ALL_PRODUCTS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};
export const insertconfiguration = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/insertexamconfiguration",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: GET_ALL_PRODUCTS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getallproducts = (data) => async (dispatch) => {
  //console.log(data);
  if (data === undefined) {
    data = { showFreeProducts: true };
  }
  //console.log(data);
  await axios({
    method: "get",
    url:
      keys.ServicePath +
      "/api/getallproducts?showProducts=" +
      data.showFreeProducts,
  })
    .then((res) => {
      try {
        dispatch({ type: GET_ALL_PRODUCTS, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
export const getallexamconfiguration = (Id) => async (dispatch) => {
  await axios({
    method: "get",
    url: keys.ServicePath + "/api/getallexamconfiguration",
  })
    .then((res) => {
      try {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: res.data.data,
        });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const deleteproduct = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deleteproduct",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_PRODUCTS, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
export const deleteexamconfig = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deleteexamconfig",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: GET_ALL_PRODUCTS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
export const deletekeys = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deletekeys",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: DELETE_KEYS, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const insertagentenquiry = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/insertagentenquiry",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: UPDATE_DATA_ENQUIRY,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getallagentsenquiry = () => async (dispatch) => {
  await axios({
    method: "get",
    url: keys.ServicePath + "/api/getagentlistenquiry",
  })
    .then((res) => {
      try {
        dispatch({ type: GET_ALL_AGENTS_ENQUIRY, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const deleteagentenquiry = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/deleteagentenquiry",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: GET_ALL_AGENTS_ENQUIRY, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const updateagentenquiry = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/updateagentenquiry",
    data: data,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({ type: UPDATE_AGENT_ENQUIRY, payload: res.data.data });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const approveagentenquiry = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/approveagentenquiry",
    data: data,
  })
    .then((res) => {
      console.log("agent approve res ", res);
      if (res.data) {
        try {
          dispatch({
            type: GET_ALL_AGENTS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getDashboard = (Id) => async (dispatch) => {
  var apiurl = keys.ServicePath + "/api/dashboard?Id=0";
  if (Id !== undefined) apiurl = keys.ServicePath + "/api/dashboard?Id=" + Id;

  await axios({ method: "get", url: apiurl })
    .then((res) => {
      try {
        // var convertedData = {};
        // const unique = [...new Set(res.data.data.map(item => item.state))];
        // unique.map(item => {
        //   var innerdata = res.data.data.filter(
        //     innerdata => innerdata.state === item
        //   );
        //   var obj1 = {};
        //   innerdata.map(item1 => {
        //     return Object.assign(obj1, {
        //       [item1.city]: { Total: item1.total, Used: item1.used }
        //     });
        //   });
        //   return Object.assign(convertedData, { [item]: obj1 });
        // });
        // console.log(convertedData);
        dispatch({ type: GET_DASHBOARD, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getusedkeys = (startDate = "", endDate = "") => async (
  dispatch
) => {
  await axios({
    method: "get",
    url:
      keys.ServicePath +
      "/api/getusedkeys?startDate=" +
      startDate +
      "&endDate=" +
      endDate,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          dispatch({
            type: GET_USED_KEYS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

function launchBOLT(payParams, otherdata) {
  let bolt = window.bolt;
  let a = payParams;

  sessionStorage.setItem("paymentParams", payParams);
  bolt.launch(
    {
      hash: payParams.hash,
      txnid: payParams.txnid.toString(),
      key: payParams.key,
      amount: payParams.amount,
      firstname: payParams.firstname,
      email: payParams.email,
      phone: payParams.phone,
      productinfo: payParams.productinfo,
      surl: payParams.surl,
      furl: payParams.furl,
      lastname: payParams.lastname,
      curl: payParams.curl,
      address1: payParams.address1,
      address2: payParams.address2,
      city: payParams.city,
      state: payParams.state,
      country: payParams.country,
      zipcode: payParams.zipcode,
      udf1: payParams.udf1,
      udf2: payParams.udf2,
      udf3: payParams.udf3,
      udf4: payParams.udf4,
      // udf5: payParams.vpc_MerchTxnRef,
      udf5: payParams.udf5,
      pg: payParams.pg,
    },
    {
      responseHandler: async function (BOLT) {
        if (BOLT.response.txnStatus.toUpperCase() !== "CANCEL") {
          await axios({
            method: "post",
            url: keys.ServicePath + "/api/PaymentReceipt",
            data: BOLT.response,
          }).then((res) => {
            // var queryString = "provider=PAYU&";

            // Object.entries(BOLT.response).forEach(([key, value]) => {
            //   queryString += key + "=" + value + "&";
            // });
            // queryString = queryString.slice(0, -1);

            window.location.href = res.data;
          });

          // var form = document.createElement("form");
          // form.setAttribute("method", "POST");

          // form.setAttribute("action", payParams.surl);

          // Object.entries(BOLT.response).forEach(([key, value]) => {
          //   console.log(key, value);
          //   var hiddenField = document.createElement("input");
          //   hiddenField.setAttribute("type", "hidden");
          //   hiddenField.setAttribute("name", key);
          //   hiddenField.setAttribute("value", value);
          //   form.appendChild(hiddenField);
          // });

          // document.body.appendChild(form);
          // console.log(form);
          // form.submit();
        } else if (BOLT.response.txnStatus.toUpperCase() === "CANCEL") {
          //let s=JSON.stringify(sessionStorage.getItem("paymentParams"));

          await axios({
            method: "post",
            url: keys.ServicePath + "/api/DeleteLicenceKey",
            data: a,
          }).then((res) => {
            console.log(res);
          });
          //DeleteLicenceKey
        }
      },
      catchException: function (BOLT) {
        alert(BOLT.message);
      },
    }
  );
}
export const boltpayment = () => {
  alert();
  launchBOLT(null);
};

export const redirectpayment = (obj) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/testpost",
  }).then((res) => {
    window.location.href = res.data;
    console.log(res);
  });
};
export const payment = (obj) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/api/pay",
    data: obj,
  })
    .then((res) => {
      // console.log(res);
      if (res.data) {
        try {
          var obj = {};
          obj.hash = res.data.params.hash;
          obj.amount = parseFloat(res.data.params.amount);
          obj = res.data.params;
          //boltpayment();
          launchBOLT(obj, res.data);
          // var form = document.createElement("form");
          // form.setAttribute("method", "POST");

          // form.setAttribute("action", res.data.actionurl);

          // Object.entries(obj).forEach(([key, value]) => {
          //   var hiddenField = document.createElement("input");
          //   hiddenField.setAttribute("type", "hidden");
          //   hiddenField.setAttribute("name", key);
          //   hiddenField.setAttribute("value", value);
          //   form.appendChild(hiddenField);
          // });

          // document.body.appendChild(form);
          // form.submit();
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  //console.log(data);
};

export const getReceiptNo = () => async (dispatch) => {
  var apiurl = keys.ServicePath + "/api/getreceiptno";

  await axios({ method: "get", url: apiurl })
    .then((res) => {
      try {
        dispatch({ type: GET_RECEIPT_NO, payload: res.data.data[0].receiptno });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const getPendingInvoices = () => async (dispatch) => {
  var apiurl = keys.ServicePath + "/api/getpendinginvoices";

  await axios({ method: "get", url: apiurl })
    .then((res) => {
      try {
        dispatch({ type: GET_PENDING_INVOICES, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const insertReceipt = (obj) => async (dispatch) => {
  var apiurl = keys.ServicePath + "/api/insertReceipt";

  
  await axios({ method: "post", url: apiurl,data:obj })
    .then((res) => {
      try {
        dispatch({ type: GET_PENDING_INVOICES, payload: res.data.data });
      } catch (exc) {
        console.log("exception", exc);
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
