import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import {
  generatelicencekeys,
  getalllicencekeys,
  getalllicencekeysByDate,
  getallagents,
  getallproducts,
  deletekeys,
  getAllPurchaseForm,
  getReceiptNo,
} from "../../actions/user";
import GridActions from "../common/ag-grid/GridActions";
import Select from "react-select";
import Modal from "react-responsive-modal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DatePicker from "react-date-picker";

const moment = require("moment");
const dateFormat = require("dateformat");
const randomstring = require("randomstring");
const keys = require("../../config/keys");
class LicKeyGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showform: false,
      codecount: 0,
      keylength: 8,
      lickey: [],
      batch: "",
      keys: "",
      keysissued: 0,
      keysused: 0,
      rowDatakeys: [],
      charsetoptions: [
        { value: "123456789", label: "Numbers" },
        { value: "ABCDEFGHIJKLMNPQRSTUVWXYZ", label: "Alphabets" },
        { value: "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789", label: "Alphanumeric" },
      ],
      paymentmode: [
        { value: "CASH", label: "CASH" },
        { value: "CREDIT", label: "CREDIT" },
      ],
      charset: "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789",
      selectedcharset: {
        value: "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789",
        label: "Alphanumeric",
      },
      colDefs: [
        {
          field: "username",
          headerName: "User Name",
          width: 120
          // filter: "agSetColumnFilter"
        },
        {
          field: "agentname",
          headerName: "Agent Name",
          // filter: "agSetColumnFilter"
        },
        {
          field: "city",
          headerName: "City",
          width: 120
          // filter: "agSetColumnFilter"
        },

        { field: "batchcode", headerName: "Batch Code", width: 120 },
        {
          field: "lkey",
          headerName: "Licence Keys",
          width: 100,
          // autoHeight: true,
          cellClass: "cell-wrap-text",
        },
        { field: "lkeycount", headerName: "Keys count", width: 100 },
        { field: "keysused", headerName: "Used Keys", width: 100 },
        { field: "createddatetime", headerName: "Created-on" },
        {
          headerName: "Actions",
          width: 160,
          cellRendererFramework: GridActions,
          field: "id",
          colId: "params",
          pinned: "right",
        },
      ],
      defaultColDef: { filter: "agTextColumnFilter" },
      rowData: [],
      context: { componentParent: this },
      showGridDelete: true,
      showGridEdit: false,
      showGridCustom3: true,
      showGridCustom2: true,
      showGridCustom1: true,
      showGridCustom1Lb3: "Invoice",
      showGridCustom1Lb2: "Print",
      showGridCustom1Lbl: "Excel",
      licencekeys: [],
      modelOpen: false,
      modelOpen1: false,
      loader: false,
      resultEmpty: false,
      showlicencekeygrid: false,
      colDefskeys: [
        // {
        //   field: "agentname",
        //   headerName: "Agent Name",
        //   filter: "agSetColumnFilter"
        // },
        //{ field: "batchcode", headerName: "Batch Code", width: 150 },
        {
          field: "licencekey",
          headerName: "Licence Keys",
          cellClassRules: {
            used: function (params) {
              return params.data.isused === 1;
            },
          },
        },
        {
          field: "usedon",
          headerName: "Used On",
          cellClassRules: {
            used: function (params) {
              return params.data.isused === 1;
            },
          },
        },
        {
          field: "name",
          headerName: "Name",
          cellClassRules: {
            used: function (params) {
              return params.data.isused === 1;
            },
          },
        },
        {
          field: "mobile",
          headerName: "Mobile",
          cellClassRules: {
            used: function (params) {
              return params.data.isused === 1;
            },
          },
        },
        {
          field: "email",
          headerName: "Email",
          cellClassRules: {
            used: function (params) {
              return params.data.isused === 1;
            },
          },
        },
      ],
      excelStyles: [
        {
          id: "used",
          interior: {
            color: "#FF0000",
            pattern: "Solid",
          },
        },
      ],
    };
  }

  componentDidMount = async () => {
    await this.setState({ loader: true });
    this.props.getReceiptNo();
    await this.props.getalllicencekeys();

    const allKeyIssued = await this.props.lstlickeys.reduce(
      (allKeyIssued, lstAgent) => allKeyIssued + lstAgent.lkeycount,
      0
    );
    const allKeyUsed = await this.props.lstlickeys.reduce(
      (allKeyUsed, lstAgent) => allKeyUsed + lstAgent.keysused,
      0
    );
    this.setState({ keysissued: allKeyIssued, keysused: allKeyUsed });
    this.setState(
      {
        loader: false,
        receiptno: this.props.receiptno,
        // contactphone: this.props.lstlickeys[0].contactphone
      },
      async () => {
        await this.props.getallagents();
        await this.props.getallproducts();
        this.generatebatchno();
      }
    );
  };

  generatebatchno = () => {
    var batch = randomstring.generate({ length: 9, charset: "numeric" });

    this.setState({ batch: batch });
  };
  onChange = async (selectedOption) =>
    await this.setState({ agentid: selectedOption.value });
  onProductChange = async (selectedOption) => {
    await this.setState({
      productid: selectedOption.value,
      codecount: selectedOption.quantity,
      rate:selectedOption.rate,
      amount: selectedOption.quantity * selectedOption.rate,
    });

    this.generateCode();
  };

  onCharsetChange = async (selectedOption) => {
    await this.setState({
      charset: selectedOption.value,
      selectedcharset: selectedOption,
    });
    this.generateCode();
  };

  onPaymentmodeChange = async (selectedOption) => {
    await this.setState({
      selectedPaymentMode: selectedOption.value,
      selectedOption,
    });
    this.generateCode();
  };
  onInputChange = async (e) =>
    await this.setState({ [e.target.name]: e.target.value });

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // params.api.sizeColumnsToFit();
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const formdata = {
      agentid: this.state.agentid,
      productid: this.state.productid,
      batchno: this.state.batch,
      licencekeys: this.state.lickey,
      contactphone: this.state.contactphone,

      amount:this.state.amount,
      paymentmode:this.state.selectedPaymentMode,
      receiptno:this.state.receiptno
    };

    await this.props.generatelicencekeys(formdata);
    await this.props.getalllicencekeys();
    await this.generatebatchno();

    this.setState({ agentid: 0, lickey: [], keys: "", showform: false });
  };

  agGridDelete = async (row) => {
    await this.props.deletekeys({ id: row.batchcode });
    await this.props.getalllicencekeys();
  };

  agGridCustom2 = async (row) => {
    //console.log(moment(row.createddatetime).format("DD/MM/YYYY"),dateFormat(Date(row.createddatetime),"dd/mm/yyyy"))
    await this.setState(
      {
        detaillicencekeys: row.licencedetails, // row.lkey.split(","),
        documentname: row.agentnamewithcity,
        batchcode: row.batchcode,
        date: dateFormat(Date(row.createddatetime), "dd/mm/yyyy"),
      },
      () => {
        this.setState({ modelOpen: true });
      }
    );
  };

  agGridCustom3 = async (row) => {
    // console.log("print row", row);
    await this.setState({ loader: true });
    await this.props.getAllPurchaseForm({
      agentId: row.agentid,
      batchCode: row.batchcode,
    });
    // console.log('Res == ',this.props.listPurchaseForm)

    if (this.props.listPurchaseForm.length > 0) {
      await this.setState(
        {
          agentname: this.props.listPurchaseForm[0].agentname,
          address: this.props.listPurchaseForm[0].address,
          cityname: this.props.listPurchaseForm[0].cityname,
          statename: this.props.listPurchaseForm[0].statename,
          stateid: this.props.listPurchaseForm[0].stateid,
          contactemail: this.props.listPurchaseForm[0].contactemail,
          contactphone: this.props.listPurchaseForm[0].contactphone,
          date: this.props.listPurchaseForm[0].createddatetime,
          quantity: this.props.listPurchaseForm[0].quantity,
          rate: this.props.listPurchaseForm[0].rate,
          total: this.props.listPurchaseForm[0].total,
          product: this.props.listPurchaseForm[0].product,
          gstno: this.props.listPurchaseForm[0].gstno,
          batchCode: row.batchcode,
          resultEmpty: false,
        },
        () => {
          this.setState({ modelOpen1: true, loader: false });
        }
      );
    } else {
      this.setState({ modelOpen1: true, loader: false, resultEmpty: true });
    }
  };

  agGridCustom1 = async (row) => {
    var params = {
      exportMode: "xlsx",
      allColumns: true,
      fileName: row.agentnamewithcity + ".xlsx",
      sheetName: "Licence_keys",
    };
    this.setState(
      {
        rowDatakeys: row.licencedetails,
        bcode: row.batchcode,
        documentname: row.agentnamewithcity,
      },
      () => {
        this.gridApikeys.exportDataAsExcel(params);
      }
    );
  };

  onCloseModal = () => {
    this.setState({ modelOpen: false, modelOpen1: false });
  };
  generateCode = () => {
    if (this.state.codecount > 0) {
      const s = new Set();
      while (s.size < this.state.codecount) {
        s.add(
          randomstring.generate({
            length: this.state.keylength,
            charset: this.state.charset,
            capitalization: "uppercase",
          })
        );
      }

      this.setState({
        lickey: [...s],
        keys: ' "' + [...s].join('","') + '"',
        amount : this.state.rate * this.state.codecount
      });
    }
  };

  onGridReadykeys = (params) => {
    this.gridApikeys = params.api;
    this.gridColumnApikeys = params.columnApi;
    //params.api.sizeColumnsToFit();
  };

  printDocument = (row) => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save(this.state.documentname + "_keys.pdf");
    });
  };

  selectedrow = () => {
    var row = this.gridApi.getSelectedRows();
    
    this.setState({
      rowDatakeys: row[0].licencedetails,
      bcode: row[0].batchcode,
      documentname: row[0].agentnamewithcity,
      batchcode: row[0].batchcode,
    });
  };

  onStartDateChange = (startDate) => {
    this.setState({ startDate });
  };
  onEndDateChange = (endDate) => {
    this.setState({ endDate });
  };

  handleFilter = async (e) => {
    e.preventDefault();
    await this.props.getalllicencekeysByDate(
      moment(this.state.startDate).format("YYYY-MM-DD"),
      moment(this.state.endDate).format("YYYY-MM-DD")
    );
    const allKeyIssued = await this.props.lstlickeys.reduce(
      (allKeyIssued, lstAgent) => allKeyIssued + lstAgent.lkeycount,
      0
    );
    const allKeyUsed = await this.props.lstlickeys.reduce(
      (allKeyUsed, lstAgent) => allKeyUsed + lstAgent.keysused,
      0
    );
    this.setState({ keysissued: allKeyIssued, keysused: allKeyUsed });
  };

  showAllData = async (e) => {
    e.preventDefault();
    this.setState({ startDate: "", endDate: "" });
    await this.props.getalllicencekeys();
    const allKeyIssued = await this.props.lstlickeys.reduce(
      (allKeyIssued, lstAgent) => allKeyIssued + lstAgent.lkeycount,
      0
    );
    const allKeyUsed = await this.props.lstlickeys.reduce(
      (allKeyUsed, lstAgent) => allKeyUsed + lstAgent.keysused,
      0
    );
    this.setState({ keysissued: allKeyIssued, keysused: allKeyUsed });
  };
  onFilterModified = async (params) => {
    params.api.redrawRows();
    
    const allKeyIssued = await params.api.rowModel.rowsToDisplay.reduce(
      (allKeyIssued, lstAgent) => allKeyIssued + lstAgent.data.lkeycount,
      0
    );
    const allKeyUsed = await params.api.rowModel.rowsToDisplay.reduce(
      (allKeyUsed, lstAgent) => allKeyUsed + lstAgent.data.keysused,
      0
    );
    this.setState({ keysissued: allKeyIssued, keysused: allKeyUsed });

    // paramsAPI.forEachNodeAfterFilterAndSort((node, index) => {
    //   objPropList.forEach(arrProps => {
    //     if (node.data.hasOwnProperty(arrProps)) {
    //       footerRowObj[arrProps] =
    //         footerRowObj[arrProps] + node.data[arrProps];
    //     }
    //   });
    // });

    //this.getCustomPinnedRows(params.api);
  };
  render() {
    return (
      <section className="widget">
        {this.state.loader ? (
          <div className="overlay">
            <div id="loader"></div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-md-2">
            <h3 className="card-title">
              Licence Key{" "}
              <p className="card-category">{"Generate Licencekey"}</p>
            </h3>
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-3 form-group">
                <label>Start Date : </label>
                <DatePicker
                  onChange={this.onStartDateChange}
                  value={this.state.startDate}
                  format="y-MM-dd"
                />
              </div>
              <div className="col-md-3 form-group">
                <label>End Date : </label>
                <DatePicker
                  onChange={this.onEndDateChange}
                  value={this.state.endDate}
                  format="y-MM-dd"
                />
              </div>
              <div className="col-md-6 form-group">
                <button
                  className="btn btn-sm btn-success mr-xs "
                  onClick={this.handleFilter}
                >
                  Show Data
                </button>
                <button
                  className="btn btn-sm btn-success mr-xs "
                  onClick={this.showAllData}
                >
                  Show All Data
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="widget-body">
          <div className="row">
            {this.state.showform && (
              <div className="col-md-6">
                <h4>Generate Licence Key</h4>
                <div className="widget-padding-md border rounded">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label>Company</label>

                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="usertype"
                        onChange={this.onChange}
                        options={this.props.lstAgents.map((item) => {
                          return {
                            value: item.id,
                            label: item.agentnamewithcity,
                          };
                        })}
                        required
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      <label>Product</label>

                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="product"
                        onChange={this.onProductChange}
                        options={this.props.lstProduct.map((item) => {
                          return {
                            value: item.id,
                            label: item.product,
                            rate: item.rate,
                            quantity: item.quantity,
                          };
                        })}
                        required
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Batch No : </label>
                      <input
                        id="batchno"
                        name="batchno"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="RTOKL0001"
                        required
                        defaultValue={this.state.batch}
                      />
                    </div>
                    <div className="col-md-2 form-group">
                      <label>Lic. Key Length </label>
                      <input
                        id="keylength"
                        name="keylength"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter no of codes to generate"
                        onChange={this.onInputChange}
                        required
                        value={this.state.keylength}
                      />
                    </div>
                    <div className="col-md-2 form-group">
                      <label>No. of code to generate </label>
                      <input
                        id="codecount"
                        name="codecount"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Enter no of codes to generate"
                        onChange={this.onInputChange}
                        value={this.state.codecount}
                        required
                        onBlur={this.generateCode}
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Code Character</label>

                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="charset"
                        onChange={this.onCharsetChange}
                        options={this.state.charsetoptions}
                        value={this.state.selectedcharset}
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      <label>Generated Key</label>

                      <textarea
                        rows={3}
                        value={this.state.keys}
                        className="form-control input-lg input"
                        disabled
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Receipt No</label>

                      <input
                        id="receiptno"
                        name="receiptno"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Receipt No"
                        onChange={this.onInputChange}
                        value={this.state.receiptno}
                        required
                        readOnly
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <label>Payment Mode</label>

                      <Select
                        className="input-select input-select"
                        classNamePrefix="select"
                        name="charset"
                        onChange={this.onPaymentmodeChange}
                        options={this.state.paymentmode}
                        value={this.state.selectedOption}
                      />
                    </div>
                    <div className="col-md-4 form-group">
                      <label>Amount</label>

                      <input
                        id="amount"
                        name="amount"
                        type="text"
                        className="form-control input-lg input"
                        placeholder="Amount"
                        value={this.state.amount}
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-action bg-widget position-relative form-group">
                    <button
                      className="btn btn-sm btn-success mr-xs "
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      type="button"
                      onClick={() =>
                        this.setState({
                          agentid: 0,
                          lickey: [],
                          keys: "",
                          showform: false,
                        })
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}{" "}
            <div className={this.state.showform ? "col-md-6" : "col-md-12"}>
              <h4>
                <button
                  className="btn btn-success"
                  onClick={() => this.setState({ showform: true })}
                >
                  <i className="fa fa-plus" />
                  {"  Add Licence Key"}
                </button>
                <span className="keys">
                  {" TOTAL KEY ISSUED : "}
                  <span className="green_font_bold">
                    {this.state.keysissued}
                  </span>
                </span>
                <span className="keys">
                  {" TOTAL KEY USED : "}
                  <span className="green_font_bold">{this.state.keysused}</span>
                </span>
              </h4>
              <div className="widget-padding-md border rounded">
                <div
                  className="ag-theme-balham"
                  style={{ height: 500, width: "100%" }}
                >
                  <AgGridReact
                    columnDefs={this.state.colDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.lstlickeys}
                    onSelectionChanged={this.selectedrow}
                    onGridReady={this.onGridReady}
                    context={this.state.context}
                    floatingFilter={true}
                    enableSorting={true}
                    pagination={this.props.ui.ag_grid_ui.pagination}
                    paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                    onFilterModified={this.onFilterModified}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.modelOpen}
          onClose={this.onCloseModal}
          center
          // classNames={{
          //   overlay: "comfirm_modal_opacity",
          //   modal: "comfirm_modal",
          //   transitionEnter: "transitionEnter,",
          //   transitionEnterActive: "transitionEnterActive",
          //   transitionExit: "transitionExitActive",
          //   transitionExitActive: "transitionExitActive,"
          // }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="row" id="divToPrint">
                <div className="col-lg-12">
                  <h3 className="h6LicenceKey">{this.state.documentname}</h3>
                  <h4 className="h6LicenceKey">
                    {"Batch Code: " +
                      this.state.batchcode +
                      "| Date :" +
                      this.state.date}
                  </h4>
                  <hr className="ruler" />
                </div>

                {this.state.detaillicencekeys &&
                  this.state.detaillicencekeys.map((item, index) => {
                    return (
                      <div key={index} className="col-md-2">
                        <h6
                          className={
                            item.isused === 1
                              ? "h6LicenceKey red"
                              : "h6LicenceKey"
                          }
                        >
                          {item.licencekey}
                        </h6>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-12">
              <button
                className="btn btn-sm btn-success mr-xs "
                onClick={this.printDocument}
              >
                <i className="fa fa-print" /> Print
              </button>
            </div>
          </div>
        </Modal>

        <div className="col-md-4 displaynone">
          <h4>{"Keys list (Batch no : " + this.state.bcode + ")"}</h4>
          <div className="widget-padding-md border rounded">
            <button
              className="btn btn-sm btn-success mr-xs "
              onClick={this.excelexport}
            >
              <i className="fa fa-file-excel-o" /> {" Excel Export"}
            </button>
            <div
              className="ag-theme-balham"
              style={{ height: 500, width: "100%" }}
            >
              <AgGridReact
                columnDefs={this.state.colDefskeys}
                rowData={this.state.rowDatakeys}
                onGridReady={this.onGridReadykeys}
                floatingFilter={true}
                enableSorting={true}
                pagination={this.props.ui.ag_grid_ui.pagination}
                paginationPageSize={this.props.ui.ag_grid_ui.pageSize}
                excelStyles={this.state.excelStyles}
                // rowClassRules={this.state.rowClassRules}
              />
            </div>
          </div>
        </div>

        <Modal open={this.state.modelOpen1} onClose={this.onCloseModal} center>
          <div className="row">
            <div className="col-lg-12">
              <div className="row invoice-body" id="divToPrint">
                <section>
                  <header className="clearfix">
                    <div id="logo">
                      <img
                        alt="logo"
                        src={
                          keys.authorizedURL +
                          "/static/media/favicon.7645d66a.png"
                        }
                      />
                    </div>
                    <div id="company">
                      <h2 className="name">BHAVESH AJMERI</h2>
                      <div>G-3, Shiv Chambers, Sardar Bhavan Lane,</div>
                      <div>Raopura, Vadodara - 390001. Gujarat. (India)</div>
                      <div>
                        9426376678 |&nbsp;
                        <a href="mailto:bhavesh456ajmeri@gmail.com">
                          bhavesh456ajmeri@gmail.com
                        </a>
                      </div>
                    </div>
                  </header>

                  {this.state.resultEmpty ? (
                    <main className="text-center">No data found</main>
                  ) : (
                    <main>
                      <div id="details" className="clearfix">
                        <div id="client">
                          {/* <div className="to">INVOICE TO:</div> */}
                          <h2 className="name">M/s. {this.state.agentname}</h2>
                          <div className="address">
                            {this.state.address}, {this.state.cityname},{" "}
                            {this.state.statename}
                          </div>
                          <div className="email">
                            <a href={this.state.contactemail}>
                              {this.state.contactemail}
                            </a>
                          </div>
                        </div>
                        <div id="invoice">
                          <h1>Invoice No # {this.state.batchCode}</h1>
                          <div className="date">GST No: {this.state.gstno}</div>
                          <div className="date">
                            Date of Invoice: {this.state.date}
                          </div>
                        </div>
                      </div>
                      <table
                        className="borderTable"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th className="no">#</th>
                            <th className="desc">Particulars</th>
                            <th className="unit">Qty.</th>
                            <th className="qty">Rate</th>
                            <th className="total">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="no">01</td>
                            <td className="desc">
                              <h3>MDTS Book</h3>
                            </td>
                            <td className="unit">{this.state.quantity}</td>
                            <td className="qty">&#x20b9;{this.state.rate}</td>
                            <td className="total">
                              &#x20b9;{this.state.total}{" "}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot border="0">
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">SUBTOTAL</td>
                            <td>
                              &#x20b9;
                              {this.state.total - (5 * this.state.total) / 100}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">TAX 5%</td>
                            <td>&#x20b9;{(5 * this.state.total) / 100}</td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">GRAND TOTAL</td>
                            <td>&#x20b9;{this.state.total}</td>
                          </tr>
                        </tfoot>
                      </table>

                      {this.state.totalInWords &&
                      this.state.totalInWords !== "" ? (
                        <div id="invoice">
                          Amount Chargeable (in words) :{" "}
                          {this.state.totalInWords}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.stateid && this.state.stateid === 12 ? (
                        <table
                          className="taxTable taxTable1"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="desc">
                                CGST
                              </th>
                              <th colSpan="2" className="unit">
                                SGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="desc">Rate (%)</td>
                              <td className="desc">Amt.</td>
                              <td className="unit">Rate (%)</td>
                              <td className="unit">Amt.</td>
                            </tr>

                            <tr>
                              <td className="no">
                                &#x20b9;
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="desc">2.5</td>
                              <td className="desc">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                              <td className="unit">2.5</td>
                              <td className="unit">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table
                          className="taxTable"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="qty">
                                IGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="qty">Rate (%)</td>
                              <td className="qty">Amt.</td>
                            </tr>
                            <tr>
                              <td className="no">
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="qty">5</td>
                              <td className="qty">
                                &#x20b9;{(5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      <table className="taxTable" width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <b>Terms & Condition : </b>Subject to{" "}
                              {this.state.cityname} Jurisdiction
                            </td>
                            <td>
                              <b>Payment Mode : </b>Cash
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div id="notices">
                        <div className="notice">
                          NOTICE : IGST will be Count only Out of Gujarat Sale.
                          Otherwise Filled remain Blank.
                        </div>
                      </div>
                    </main>
                  )}

                  <div>
                    --------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </div>

                  <header className="clearfix">
                    <div id="logo">
                      <img
                        alt="logo"
                        src={
                          keys.authorizedURL +
                          "/static/media/favicon.7645d66a.png"
                        }
                      />
                    </div>
                    <div id="company">
                      <h2 className="name">BHAVESH AJMERI</h2>
                      <div>G-3, Shiv Chambers, Sardar Bhavan Lane,</div>
                      <div>Raopura, Vadodara - 390001. Gujarat. (India)</div>
                      <div>
                        9426376678 |&nbsp;
                        <a href="mailto:bhavesh456ajmeri@gmail.com">
                          bhavesh456ajmeri@gmail.com
                        </a>
                      </div>
                    </div>
                  </header>

                  {this.state.resultEmpty ? (
                    <main className="text-center">No data found</main>
                  ) : (
                    <main>
                      <div id="details" className="clearfix">
                        <div id="client">
                          {/* <div className="to">INVOICE TO:</div> */}
                          <h2 className="name">M/s. {this.state.agentname}</h2>
                          <div className="address">
                            {this.state.address}, {this.state.cityname},{" "}
                            {this.state.statename}
                          </div>
                          <div className="email">
                            <a href={this.state.contactemail}>
                              {this.state.contactemail}
                            </a>
                          </div>
                        </div>
                        <div id="invoice">
                          <h1>Invoice No # {this.state.batchCode}</h1>
                          <div className="date">
                            Date of Invoice: {this.state.date}
                          </div>
                          <div className="date">
                            Date: {moment().format("DD/MM/YY")}
                          </div>
                        </div>
                      </div>
                      <table
                        className="borderTable"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th className="no">#</th>
                            <th className="desc">Particulars</th>
                            <th className="unit">Qty.</th>
                            <th className="qty">Rate</th>
                            <th className="total">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="no">01</td>
                            <td className="desc">
                              <h3>MDTS Book</h3>
                            </td>
                            <td className="unit">{this.state.quantity}</td>
                            <td className="qty">&#x20b9;{this.state.rate}</td>
                            <td className="total">
                              &#x20b9;{this.state.total}{" "}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot border="0">
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">SUBTOTAL</td>
                            <td>
                              &#x20b9;
                              {this.state.total - (5 * this.state.total) / 100}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">TAX 5%</td>
                            <td>&#x20b9;{(5 * this.state.total) / 100}</td>
                          </tr>
                          <tr>
                            <td colSpan="2"></td>
                            <td colSpan="2">GRAND TOTAL</td>
                            <td>&#x20b9;{this.state.total}</td>
                          </tr>
                        </tfoot>
                      </table>

                      {this.state.totalInWords &&
                      this.state.totalInWords !== "" ? (
                        <div id="invoice">
                          Amount Chargeable (in words) :{" "}
                          {this.state.totalInWords}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.stateid && this.state.stateid === 12 ? (
                        <table
                          className="taxTable taxTable1"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="desc">
                                CGST
                              </th>
                              <th colSpan="2" className="unit">
                                SGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="desc">Rate (%)</td>
                              <td className="desc">Amt.</td>
                              <td className="unit">Rate (%)</td>
                              <td className="unit">Amt.</td>
                            </tr>

                            <tr>
                              <td className="no">
                                &#x20b9;
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="desc">2.5</td>
                              <td className="desc">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                              <td className="unit">2.5</td>
                              <td className="unit">
                                &#x20b9;{(2.5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table
                          className="taxTable"
                          border="1"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th className="no">Taxable Value</th>
                              <th colSpan="2" className="qty">
                                IGST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="no"></td>
                              <td className="qty">Rate (%)</td>
                              <td className="qty">Amt.</td>
                            </tr>
                            <tr>
                              <td className="no">
                                {this.state.total -
                                  (5 * this.state.total) / 100}
                              </td>
                              <td className="qty">5</td>
                              <td className="qty">
                                &#x20b9;{(5 * this.state.total) / 100}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      <table className="taxTable" width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <b>Terms & Condition : </b>Subject to{" "}
                              {this.state.cityname} Jurisdiction
                            </td>
                            <td>
                              <b>Payment Mode : </b>Cash
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div id="notices">
                        <div className="notice">
                          NOTICE : IGST will be Count only Out of Gujarat Sale.
                          Otherwise Filled remain Blank.
                        </div>
                      </div>
                    </main>
                  )}
                </section>
              </div>
            </div>
            <div className="col-lg-12">
              <button
                className="btn btn-sm btn-success mr-xs "
                onClick={this.printDocument}
              >
                <i className="fa fa-print" /> Print
              </button>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

function mapStateToProps({
  auth,
  lstlickeys,
  ui,
  lstAgents,
  lstProduct,
  listPurchaseForm,
  receiptno,
}) {
  return {
    auth,
    ui,
    lstlickeys,
    lstAgents,
    lstProduct,
    listPurchaseForm,
    receiptno,
  };
}

// const mapStateToProps() {
//   return {
//     user,
//     lstUsers
//   };
// };
export default connect(mapStateToProps, {
  generatelicencekeys,
  getalllicencekeys,
  getalllicencekeysByDate,
  getallagents,
  getallproducts,
  deletekeys,
  getAllPurchaseForm,
  getReceiptNo,
})(LicKeyGenerator);
