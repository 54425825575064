import { combineReducers } from "redux";
import auth from "./authReducer";
import {
  lstUsers,
  lstcitystate,
  lstAgents,
  insertAgent,
  insertPurchaseForm,
  lstlickeys,
  listPurchaseForm,
  generatelicencekeys,
  lstProduct,
  lstAgentsEnquiry,
  insertAgentEnquiry,
  updateAgentEnquiry,
  deleteKeys,
  lststate,
  dashboardDetails,
  lstusedkeys,
  receiptno,
  pendinginvoicelist
} from "./users";
import ui from "./UI";

export default combineReducers({
  auth,
  lstUsers,
  lstcitystate,
  lstAgents,
  insertAgent,
  insertPurchaseForm,
  lstlickeys,
  listPurchaseForm,
  generatelicencekeys,
  lstProduct,
  lstAgentsEnquiry,
  insertAgentEnquiry,
  updateAgentEnquiry,
  ui,
  deleteKeys,
  lststate,
  dashboardDetails,
  lstusedkeys,
  receiptno,
  pendinginvoicelist
});
